export enum DownloadToGoTypeKeys {
  UPDATE_DOWNLOAD_TO_GO = 'UPDATE_DOWNLOAD_TO_GO',
  UPDATE_DOWNLOAD_CONTENT_URL = 'UPDATE_DOWNLOAD_CONTENT_URL',
  OPEN_DOWNLOAD_TO_GO = 'OPEN_DOWNLOAD_TO_GO',
}

export interface IUpdateDownloadToGoAction {
  readonly type: DownloadToGoTypeKeys.UPDATE_DOWNLOAD_TO_GO;
  readonly payload: {
    showDownloadToGoButton: boolean;
    isDownloadAvailable: boolean;
    consumptionPlatform: string;
    downloadToGoAppUrl: string;
    isStreamAvailable: boolean;
    contentID: string;
    tracking: any;
  };
}

export interface IUpdateDownloadToGoParameter {
  showDownloadToGoButton: boolean;
  isDownloadAvailable: boolean;
  consumptionPlatform: string;
  downloadToGoAppUrl: string;
  isStreamAvailable: boolean;
  contentID: string;
  tracking: any;
}
/**
 * updateDownloadToGo
 * @param {bool} showDownloadToGoButton
 * @param {bool} isDownloadAvailable
 * @param {string} consumptionPlatform
 * @param {string} downloadToGoAppUrl
 * @param {bool} isStreamAvailable
 * @param {string} contentID
 * @param {object} tracking
 * @returns {object} action UPDATE_DOWNLOAD_TO_GO
 */
export const updateDownloadToGo = ({
  showDownloadToGoButton,
  isDownloadAvailable,
  consumptionPlatform,
  downloadToGoAppUrl,
  isStreamAvailable,
  contentID,
  tracking = {},
}: IUpdateDownloadToGoParameter): IUpdateDownloadToGoAction => ({
  payload: {
    consumptionPlatform,
    contentID,
    downloadToGoAppUrl,
    isDownloadAvailable,
    isStreamAvailable,
    showDownloadToGoButton,
    tracking,
  },
  type: DownloadToGoTypeKeys.UPDATE_DOWNLOAD_TO_GO,
});

export interface IUpdateDownloadContentUrlAction {
  readonly type: DownloadToGoTypeKeys.UPDATE_DOWNLOAD_CONTENT_URL;
  readonly payload: {
    downloadContentUrl: string;
  };
}

/**
 * updateDownloadContentUrl
 * @param {string} downloadContentUrl
 * @returns {object} action UPDATE_DOWNLOAD_CONTENT_URL
 */
export const updateDownloadContentUrl = (
  downloadContentUrl: string
): IUpdateDownloadContentUrlAction => ({
  payload: { downloadContentUrl },
  type: DownloadToGoTypeKeys.UPDATE_DOWNLOAD_CONTENT_URL,
});

export interface ID2GOpenModal {
  readonly type: DownloadToGoTypeKeys.OPEN_DOWNLOAD_TO_GO;
}
export const openDownloadToGo = (): ID2GOpenModal => ({
  type: DownloadToGoTypeKeys.OPEN_DOWNLOAD_TO_GO,
});

export type DownloadToGoActionTypes =
  | IUpdateDownloadToGoAction
  | IUpdateDownloadContentUrlAction
  | ID2GOpenModal;
