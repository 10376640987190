import { showDownloadToGo } from '../../helpers/platform/platform-helper';
import * as D2GSelectors from '../../selectors/downloadToGo/downloadToGo-selectors';
import * as DownloadToGoActions from '../../store/reducers/actions/DownloadToGo-actions';
import { immersiveOrPageTemplateSelector } from '../../store/slices/immersive-selectors';
import * as ApplicationSelectors from '../slices/application-selectors';

/**
 * downloadtoGoMiddleware
 * @param {function}  getState
 * @param {function}  dispatch
 * @returns {object} return next action to dispatch
 */
export const downloadtoGoMiddleware =
  ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    switch (action.type) {
      // Update store when userAgent is updated to have the correct `isMobile` param
      case 'application/setUserAgent': {
        const state = getState();
        const downloadToGo = D2GSelectors.downloadToGoSelector(state);
        const isMobile = action.userAgent?.isMobile?.isAny;
        const displayTemplate = immersiveOrPageTemplateSelector(state);
        const consumptionPlatform =
          D2GSelectors.d2gConsumptionPlatformSelector(state);
        const contentID = D2GSelectors.d2gContentIDSelector(state);
        const isStreamAvailable = D2GSelectors.isStreamAvailableSelector(state);
        const isDownloadAvailable = false;
        const downloadManagerUrl =
          ApplicationSelectors.downloadManagerUrlSelector(state);
        const showDownloadToGoButton = showDownloadToGo({
          isMobile,
          displayTemplate,
          consumptionPlatform,
          isStreamAvailable,
          contentID,
          isDownloadAvailable,
        });

        const downloadToGoAppUrl = action.userAgent?.isDesktop?.isMacOs
          ? `${downloadManagerUrl}myCanal.pkg`
          : `${downloadManagerUrl}myCANAL-setup.exe`;
        dispatch(
          DownloadToGoActions.updateDownloadToGo({
            ...downloadToGo,
            showDownloadToGoButton,
            downloadToGoAppUrl,
          })
        );
        return next(action);
      }
      default:
        return next(action);
    }
  };
