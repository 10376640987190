import type { LogApiMetadata } from './generateApiMetadata';

/**
 * Generate a loggable metadata object based on a provided data object
 *
 * This method is building a meta data object by placing some isRelevant log data at
 * places expected by the exploitation team and reported on log dashboards
 *
 * @param  data An object whose properties will be added as log metadata
 */
export const generateMetadata = (
  data: LogApiMetadata
): { meta: LogApiMetadata } => {
  const metadata = {};

  // Request id is moved to req.uniqueId
  if (data.requestId) {
    Object.assign(metadata, {
      req: {
        requestId: data.requestId,
      },
    });

    delete data.requestId;
  }

  // Status code is moved to res.statusCode
  if (data.statusCode) {
    Object.assign(metadata, {
      res: {
        statusCode: data.statusCode,
      },
    });

    delete data.statusCode;
  }

  // responseTime is calculated using startAt
  if (data.startAt) {
    Object.assign(metadata, {
      responseTime: new Date().getTime() - data.startAt,
    });

    delete data.startAt;
  }

  // Other data properties are merged as if
  return { meta: Object.assign(metadata, data) };
};
