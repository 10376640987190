import { isSomeEnum } from '@canalplus/mycanal-commons';

export enum Moods {
  Dislike = 'dislike',
  Like = 'like',
  Neutral = 'neutral',
  NoOpinion = 'noopinion',
}

export const isMoods = isSomeEnum(Moods);
