import { isEs5Platform } from '../platform/platform-helper';

export const getCookieOptions = (
  options?: Cookies.CookieAttributes
): Cookies.CookieAttributes | undefined => {
  if (isEs5Platform(navigator.userAgent)) {
    return {
      ...options,
      domain: undefined,
      sameSite: undefined,
      encode: undefined,
      secure: false,
    };
  }

  return options;
};
