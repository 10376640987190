import { createSlice } from '@reduxjs/toolkit';
import { setupAsTV, setupAsWebview } from './displayMode';

export type ContextFeatureState = {
  isFeatIframeHeightAllowed: boolean;
  isFeatImmersiveFullAllowed: boolean;
};

export const initialState: ContextFeatureState = {
  isFeatIframeHeightAllowed: false,
  isFeatImmersiveFullAllowed: false,
};

const contextFeatureSlice = createSlice({
  name: 'contextFeature',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setupAsWebview, () => {
      return {
        isFeatIframeHeightAllowed: true,
        isFeatImmersiveFullAllowed: true,
      };
    });

    builder.addCase(setupAsTV, () => {
      return {
        isFeatIframeHeightAllowed: false,
        isFeatImmersiveFullAllowed: true,
      };
    });
  },
});

export const contextFeatureReducer = contextFeatureSlice.reducer;
