import fetch from 'isomorphic-fetch';
import { TimeoutError } from './TimeoutError';

export function timeoutPromise(
  url: string,
  options: RequestInit,
  timeout: number
): Promise<Response> {
  return new Promise((resolve: (value: Response) => void, reject) => {
    const timer = setTimeout(() => {
      reject(
        new TimeoutError(
          `Error while fetching in Fetch::timeoutPromise: request to API triggered a timeout`,
          timeout
        )
      );
    }, timeout);

    fetch(url, options)
      .then((result) => {
        clearTimeout(timer);
        resolve(result);
      })
      .catch((error) => {
        reject(
          new TimeoutError(
            `Error while fetching in Fetch::timeoutPromise: ${error.toString()}`,
            timeout
          )
        );
      });
  }).catch((error) => {
    throw error;
  });
}
