import { BranSdkCurrentPageConfiguration } from '@dce-front/hodor-types/sdks/bran';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

export type AlternateRecord = BranSdkCurrentPageConfiguration['alternate'];

export type AlternateLinksState = {
  links: AlternateRecord;
};

const initialState = {
  links: undefined,
} satisfies AlternateLinksState as AlternateLinksState;

const alternateLinksSlice = createSlice({
  name: 'alternateLinks',
  initialState,
  reducers: {
    setAlternateLinks(_state, action: PayloadAction<AlternateRecord>) {
      return { links: action.payload };
    },
  },
});

export const { setAlternateLinks } = alternateLinksSlice.actions;

export const alternateLinksReducer = alternateLinksSlice.reducer;

export type AlternateLinksActions = ReturnType<
  (typeof alternateLinksSlice.actions)[keyof typeof alternateLinksSlice.actions]
>;
