const TO_MS: number = 1000;
export const TO_MN: number = 60;
export const HOURS_TO_MS: number = 60 * 60 * TO_MS;
export const DAY_IN_MS: number = 3600 * 24 * TO_MS;
export const YEAR_IN_MS: number = 86400 * 365.2425 * TO_MS;

export const SIX_AM_IN_MIN: number = 360;
export const NOON_IN_MIN: number = 720;
export const SIX_PM_IN_MIN: number = 1080;
export const EIGHT_FORTY_FIVE_PM_IN_MIN: number = 1245;
export const MIDNIGHT_IN_MIN: number = 1440;

export const MAX_AVAILABILITY_DATE_AFTER_DOWNLOAD: number = 30;
export const MAX_AVAILABILITY_DATE_AFTER_FIRST_PLAY: number = 2;
