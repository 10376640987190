import {
  LOCATION_CHANGE,
  type LocationChangeAction,
} from 'connected-react-router';
import { getFeatureTogglePerso } from '../slices/application-selectors';
import { isImmersiveSelector } from '../slices/immersive-selectors';
import { refetchPerso } from '../slices/page';
import { destroyPlayer, killPlayer } from '../slices/player';
import {
  isPlayerOpenSelector,
  playerInstanceSelector,
} from '../slices/player-selectors';
import { hasUserDataCollectedSelector } from '../slices/user-selectors';

/**
 * Handles playerInstance side effects: to destroy or kill player
 */
export const playerInstanceMiddleware: Redux.Middleware =
  ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    const state = getState();

    /**
     * When destroy player is dispatched, we'll look for playerInstance in the store and call its destroy() method.
     * Player instance itself is kept in store to avoid requesting licenses and other stuff again
     */
    if (destroyPlayer.match(action)) {
      const player = playerInstanceSelector(state);
      if (Object.keys(player).length) {
        player.destroy();
      }

      return next(action);
    }

    if (killPlayer.match?.(action)) {
      const isImmersive = isImmersiveSelector(state);
      const isPersoActive = getFeatureTogglePerso(state);
      const hasUserDataCollected = hasUserDataCollectedSelector(state);

      if (isPersoActive && !isImmersive && hasUserDataCollected) {
        dispatch(refetchPerso(true));
      }

      return next(action);
    }

    /** If player is active and user navigates back - kill player */
    if (LOCATION_CHANGE === (action as LocationChangeAction).type) {
      if (
        (action as LocationChangeAction).payload.action === 'POP' &&
        isPlayerOpenSelector(state)
      ) {
        dispatch(killPlayer());
      }

      return next(action);
    }

    return next(action);
  };
