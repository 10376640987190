import { createSlice } from '@reduxjs/toolkit';

export type DisplayMode = {
  isTV: boolean;
  isWebview: boolean;
};

export const initialState = {
  isTV: false,
  isWebview: false,
} satisfies DisplayMode as DisplayMode;

const displayModeSlice = createSlice({
  name: 'displayMode',
  initialState,
  reducers: {
    /** Setup application as TV display mode */
    setupAsTV() {
      return {
        isTV: true,
        isWebview: false,
      };
    },

    /** Setup application as Webview display mode */
    setupAsWebview() {
      return {
        isTV: false,
        isWebview: true,
      };
    },
  },
});

export const { setupAsTV, setupAsWebview } = displayModeSlice.actions;

export const displayModeReducer = displayModeSlice.reducer;

export type DisplayModeActions = ReturnType<
  (typeof displayModeSlice.actions)[keyof typeof displayModeSlice.actions]
>;
