import { SANITIZED_PLAYER } from './constants';

const sanitizePlayerReducer = (player) => ({
  ...player,
  playerInstance: SANITIZED_PLAYER.INSTANCE,
  playerElement: SANITIZED_PLAYER.ELEMENT,
});

const sanitizeState = (state) => ({
  ...state,
  player: sanitizePlayerReducer(state.player),
});

export default sanitizeState;
