import { ProfileModal } from '@canalplus/mycanal-commons';
import type { ApiV2Profile } from '@dce-front/hodor-types/api/v2/me/profiles/definitions';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

export type ProfilesModal = {
  currentView?: ProfileModal;
  selectedProfile?: ApiV2Profile;
  isKidsProfileSettings?: boolean;
  profileInput?: string;
  avatar?: string;
  isFromLanding?: boolean;
};

const initialState: ProfilesModal = {
  currentView: ProfileModal.ProfileListView,
};

const profilesModalSlice = createSlice({
  name: 'profilesModal',
  initialState,
  reducers: {
    setAvatar: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        avatar: action.payload,
      };
    },
    setIsKidsProfileSettings: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isKidsProfileSettings: action.payload,
      };
    },
    setProfileInput: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        profileInput: action.payload,
      };
    },
    setProfileView: (
      state,
      action: PayloadAction<ProfileModal | undefined>
    ) => {
      return {
        ...state,
        isFromLanding:
          action.payload === ProfileModal.ProfileCreationViewFromLanding ||
          action.payload === ProfileModal.ProfileCreationView
            ? action.payload === ProfileModal.ProfileCreationViewFromLanding
            : state.isFromLanding,
        currentView: action.payload,
      };
    },
    setSelectedProfile: (
      state,
      action: PayloadAction<ApiV2Profile | undefined>
    ) => {
      return {
        ...state,
        selectedProfile: action.payload,
      };
    },
    wipeProfileInfos: (state) => {
      return {
        ...state,
        avatar: undefined,
        profileInput: undefined,
        isKidsProfileSettings: false,
      };
    },
  },
});

export const {
  setAvatar,
  setIsKidsProfileSettings,
  setProfileInput,
  setProfileView,
  setSelectedProfile,
  wipeProfileInfos,
} = profilesModalSlice.actions;

export const profilesModalReducer = profilesModalSlice.reducer;

export type ProfilesModalActions = ReturnType<
  (typeof profilesModalSlice.actions)[keyof typeof profilesModalSlice.actions]
>;
