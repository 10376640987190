export enum ModalTypes {
  DeleteAccount = 'DELETE_ACCOUNT',
  DOWNLOAD_TO_GO_DELETION_MODAL = 'DOWNLOAD_TO_GO_DELETION_MODAL',
  DOWNLOAD_TO_GO_DOWNLOAD_MODAL = 'DOWNLOAD_TO_GO_DOWNLOAD_MODAL',
  DOWNLOAD_TO_GO_EPISODE_LIST_MODAL = 'DOWNLOAD_TO_GO_EPISODE_LIST_MODAL',
  DOWNLOAD_TO_GO_INFORMATION_MODAL = 'DOWNLOAD_TO_GO_INFORMATION_MODAL',
  DOWNLOAD_TO_GO_MAC_OS_MODAL = 'DOWNLOAD_TO_GO_MAC_OS_MODAL',
  DOWNLOAD_TO_GO_MODAL = 'DOWNLOAD_TO_GO_MODAL',
  DOWNLOAD_TO_GO_QUALITY_MODAL = 'DOWNLOAD_TO_GO_QUALITY_MODAL',
  EXTERNAL_SERVICE_DOWNLOAD_MODAL = 'EXTERNAL_SERVICE_DOWNLOAD_MODAL',
  EXTERNAL_SERVICE_STREAM_MODAL = 'EXTERNAL_SERVICE_STREAM_MODAL',
  GENERIC_MODAL = 'GENERIC_MODAL',
  MOBILE_APP_DOWNLOAD = 'MOBILE_APP_DOWNLOAD',
  MOOD_MODAL = 'MOOD_MODAL',
  PARENTAL_CODE_MODAL = 'PARENTAL_CODE_MODAL',
  PURCHASE_CODE_DESACTIVATION_MODAL = 'PURCHASE_CODE_DESACTIVATION_MODAL',
  PURCHASE_CODE_MODAL = 'PURCHASE_CODE_MODAL',
  STARTOVER_MODAL = 'STARTOVER_MODAL',
  USER_EXITS_SETTINGS_MODAL = 'USER_EXITS_SETTINGS_MODAL',
  USER_SETTINGS_MODAL = 'USER_SETTINGS_MODAL',
  VIOLENT_CONTENT_MODAL = 'VIOLENT_CONTENT_MODAL',
  ZONES_LIST_MODAL = 'ZONES_LIST_MODAL',
}
