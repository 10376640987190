import { createSelector } from '@reduxjs/toolkit';
import { initialState, type DisplayMode } from '../../store/slices/displayMode';
import { IState } from '../../store/types/State-type';

const displayModesSelector = (state: IState): DisplayMode =>
  state?.displayMode ?? initialState;

export const displayWebviewModeSelector = createSelector(
  displayModesSelector,
  (displayModes) => displayModes?.isWebview ?? initialState.isWebview
);

export const displayTVModeSelector = createSelector(
  displayModesSelector,
  (displayModes) => displayModes?.isTV ?? initialState.isTV
);
