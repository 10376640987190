if (
  'IntersectionObserver' in window &&
  'IntersectionObserverEntry' in window &&
  'intersectionRatio' in window.IntersectionObserverEntry.prototype
) {
  // Minimal polyfill for chrome <57 and other old browsers
  // See: https://github.com/w3c/IntersectionObserver/issues/211
  if (!('isIntersecting' in window.IntersectionObserverEntry.prototype)) {
    Object.defineProperty(
      window.IntersectionObserverEntry.prototype,
      'isIntersecting',
      {
        get: function () {
          return this.intersectionRatio > 0;
        },
      }
    );
  }
}
