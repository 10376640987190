import { setLocalStorageSettings } from '../../helpers/settings/settings-helper';
import { userChangeSettings } from '../slices/user';

/** Handles all side effects for settings */
export const settingsMiddleware: Redux.Middleware =
  () => (next) => (action) => {
    if (userChangeSettings.match(action)) {
      setLocalStorageSettings(action);
    }

    return next(action);
  };
