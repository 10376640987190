import Logger from '../../helpers/logger/logger-helper';

export default function removeServiceWorkers(): void {
  navigator.serviceWorker
    .getRegistrations()
    .then((registrations) => {
      if (registrations.length) {
        registrations.forEach((registeration) => registeration.unregister());
        Logger.info(
          `[SW:client] All service worker(s) registration(s) were removed. (${registrations.length} found)`
        );
      }
    })
    .catch((error) => {
      Logger.error(
        `[SW:client] Error during service worker(s) registration(s) removal : ${error.message}`
      );
      Logger.error(error.stack);
    });
}
