import { isClientSide } from '@canalplus/mycanal-commons';
import { InitParameters } from '@canalplus/sdk-hodor';
import { getPublicConfig } from '../../../helpers/config/config-helper';
import {
  getCurrentProfile,
  getProfiles,
  getProfilesUpdateDate,
} from '../../profiles/profileStorageService';

export const getSdkInitParameters = (
  profilesUpdateDate: string,
  hasUserDataCollected = false
): InitParameters => {
  const {
    api: {
      hodor: { initParameters },
    },
  } = getPublicConfig();

  const { allowedProfiles, featureToggles, ...rest } = initParameters;

  if (!isClientSide()) {
    return { featureToggles, ...rest };
  }

  const profiles = getProfiles();
  const currentProfile = getCurrentProfile();
  const hasProfilesCached = profiles && currentProfile;

  const profilesUpdateDateCached = getProfilesUpdateDate();
  const areProfilesUpdateDateEqual =
    profilesUpdateDateCached === profilesUpdateDate;

  const shouldAddHodorInitQueries =
    (hasProfilesCached && areProfilesUpdateDateEqual) || !hasUserDataCollected;

  return {
    allowedProfiles,
    featureToggles,
    ...(shouldAddHodorInitQueries && rest),
  };
};
