/* 
  NOTE: A breakpoint value indicates where the named screen size STARTS.
  It ENDS 1px before the next screen size starts.  See the comments below.
*/

export const BREAKPOINT_MOBILE = 0; // 0 - 699px
export const BREAKPOINT_TABLET = 700; // 700 - 959px
export const BREAKPOINT_LAPTOP = 960; // 960 - 1284px
export const BREAKPOINT_DESKTOP = 1285; // 1285 - 1599px
export const BREAKPOINT_LARGE_DESKTOP = 1600; // 1600 - 1919px
export const BREAKPOINT_WIDE = 1920; // 1920 - ∞

// Ordered from smallest to largest
export const ALL_SUPPORTED_BREAKPOINTS = [
  BREAKPOINT_MOBILE,
  BREAKPOINT_TABLET,
  BREAKPOINT_LAPTOP,
  BREAKPOINT_DESKTOP,
  BREAKPOINT_LARGE_DESKTOP,
  BREAKPOINT_WIDE,
];

export const BREAKPOINT_MOBILE_LABEL = 'mobile';
export const BREAKPOINT_TABLET_LABEL = 'tablet';
export const BREAKPOINT_LAPTOP_LABEL = 'laptop';
export const BREAKPOINT_DESKTOP_LABEL = 'desktop';
export const BREAKPOINT_LARGE_DESKTOP_LABEL = 'large_desktop';
export const BREAKPOINT_WIDE_LABEL = 'wide';

export const BREAKPOINT_LABELS_MAPPING = {
  [BREAKPOINT_MOBILE]: BREAKPOINT_MOBILE_LABEL,
  [BREAKPOINT_TABLET]: BREAKPOINT_TABLET_LABEL,
  [BREAKPOINT_LAPTOP]: BREAKPOINT_LAPTOP_LABEL,
  [BREAKPOINT_DESKTOP]: BREAKPOINT_DESKTOP_LABEL,
  [BREAKPOINT_LARGE_DESKTOP]: BREAKPOINT_LARGE_DESKTOP_LABEL,
  [BREAKPOINT_WIDE]: BREAKPOINT_WIDE_LABEL,
};
