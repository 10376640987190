import { Template } from '@canalplus/sdk-hodor';
import { Tracking } from '@dce-front/hodor-types/api/v2/common/dto/tracking/definitions';
import { createSelector } from '@reduxjs/toolkit';
import {
  initTrackingSelector,
  slideshowNotificationsTrackingContextSelector,
  slideshowNotificationsTrackingSelector,
} from './application-selectors';
import { errorTrackingSelector } from './error-selectors';
import {
  immersiveDisplayTemplateSelector,
  immersiveTrackingContextSelector,
  immersiveTrackingSelector,
  isImmersiveSelector,
} from './immersive-selectors';
import {
  pageDisplayTemplateSelector,
  pageTrackingContextSelector,
  pageTrackingSelector,
} from './page-selectors';
import {
  isFullScreenSelector,
  trackingContextSelector,
} from './player-selectors';
import {
  isSearchActiveSelector,
  searchTrackingSelector,
} from './search-selectors';

const effectiveTrackingSelector = createSelector(
  [
    pageTrackingSelector,
    isSearchActiveSelector,
    searchTrackingSelector,
    isImmersiveSelector,
    immersiveTrackingSelector,
    slideshowNotificationsTrackingSelector,
  ],
  (
    pageTracking,
    isSearchActive,
    searchTracking,
    isImmersive,
    immersiveTracking,
    slideshowNotificationsTracking
  ) => {
    if (slideshowNotificationsTracking) {
      return slideshowNotificationsTracking;
    }

    if (isImmersive) {
      return immersiveTracking;
    }

    if (isSearchActive) {
      return searchTracking;
    }

    return pageTracking;
  }
);

const pageOrImmersiveDisplayTemplateSelector = createSelector(
  [
    isImmersiveSelector,
    immersiveDisplayTemplateSelector,
    pageDisplayTemplateSelector,
  ],
  (isImmersive, immersiveDisplayTemplate, pageDisplayTemplates) =>
    isImmersive ? immersiveDisplayTemplate : pageDisplayTemplates
);

/**
 * Tracking context according to app state (SalePreloadT, FullScreen, or immersive, or page)
 */
export const effectiveTrackingContextSelector = createSelector(
  [
    isFullScreenSelector,
    trackingContextSelector,
    isImmersiveSelector,
    immersiveTrackingContextSelector,
    pageTrackingContextSelector,
    slideshowNotificationsTrackingContextSelector,
  ],
  (
    isFullScreen,
    playerTrackingContext,
    isImmersive,
    immersiveTrackingContext,
    pageTrackingContext,
    slideshowNotificationsContext
  ) => {
    if (slideshowNotificationsContext) {
      return slideshowNotificationsContext;
    }
    if (isFullScreen && !isImmersive) {
      return playerTrackingContext;
    }
    if (isImmersive) {
      return immersiveTrackingContext;
    }
    return pageTrackingContext;
  }
);

/**
 * Page tracking used only for close immersive (to avoid send wrong tracking when closing immersive)
 */
export const closeImmersivePageTrackingSelector = createSelector(
  [
    initTrackingSelector,
    pageTrackingSelector,
    isSearchActiveSelector,
    searchTrackingSelector,
  ],
  (initTracking, pageTracking, isSearchActive, searchTracking) => {
    if (isSearchActive) {
      return { ...initTracking, ...searchTracking };
    }

    return { ...initTracking, ...pageTracking };
  }
);

/**
 * Find tracking object depending on display template
 */
export const findTrackingSelector = createSelector(
  [
    pageOrImmersiveDisplayTemplateSelector,
    errorTrackingSelector,
    effectiveTrackingSelector,
    effectiveTrackingContextSelector,
    initTrackingSelector,
  ],
  (
    pageOrImmersiveDisplayTemplate,
    errorTracking,
    effectiveTracking,
    effectiveTrackingContext,
    effectiveInitTracking
  ) => {
    switch (pageOrImmersiveDisplayTemplate) {
      case Template.Error:
        return errorTracking as Tracking;

      case Template.CreativeMedia:
      case Template.DetailPage:
      case Template.DetailSeason:
      case Template.Quicktime:
      case Template.DetailShow:
      case Template.GabaritList:
      case Template.Landing:
      case Template.Slideshow:
        return {
          ...effectiveInitTracking,
          ...effectiveTracking,
          ...effectiveTrackingContext,
        };

      default:
        return {
          ...effectiveInitTracking,
          ...effectiveTracking,
        };
    }
  }
);
