import { log } from '../../components/VisualDebugger/html-logger';
import { ResizeMode } from '../../constants/resize';

const WIDTH_720P = 1280;

export const getResizeModeAuto = (): string => {
  // check size viewport
  // case 720p
  if (window.innerWidth <= WIDTH_720P) {
    log({
      level: 'info',
      message: `Resolution detection: 720p, window.innerWidth ${window.innerWidth}`,
    });
    return ResizeMode.RESIZE_720P;
  }

  log({
    level: 'info',
    message: `Resolution detection: 1080p, window.innerWidth ${window.innerWidth}`,
  });

  return '';
};
