export const WITH_AD = 'content with ads';
export const WITHOUT_AD = 'content without ads';
export const PLAY_DEEPLINK = 'play deeplink';
export const DEEPLINK = 'deeplink';
export const START_DOWNLOAD_DEEPLINK = 'start download deeplink';

export enum ProfileEvents {
  Create = 'create',
  Edit = 'edit',
  Change = 'change',
  Delete = 'delete',
  Select = 'select',
}

export enum ProfileType {
  Standard = 'standard',
  Kids = 'kids',
}

export enum SettingImageQualityValue {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
}

export enum TrackingEventName {
  ChangeSetting = 'change setting',
  ViewError = 'view error',
  ViewAlert = 'view alert',
  CloseAlert = 'close alert',
}

export const CHANGE_SETTING_IMAGE_QUALITY_NAME = 'ImageQuality';
export const CHANGE_SETTTING_D2G_QUALITY = 'D2gQuality';
