import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

export type StickyBar = {
  headerBar: (() => JSX.Element) | null;
  originalBar: (() => HTMLElement) | null;
};

export const initialState: StickyBar = {
  headerBar: null,
  originalBar: null,
};

const stickyBarSlice = createSlice({
  name: 'stickyBar',
  initialState,
  reducers: {
    setHeaderBar(state, action: PayloadAction<() => JSX.Element>) {
      return {
        ...state,
        headerBar: action.payload,
      };
    },

    setOriginalBar(state, action: PayloadAction<() => HTMLElement>) {
      return {
        ...state,
        originalBar: action.payload,
      };
    },

    resetStickyBar() {
      return initialState;
    },
  },
});

export const { setHeaderBar, setOriginalBar, resetStickyBar } =
  stickyBarSlice.actions;

export const stickyBarReducer = stickyBarSlice.reducer;

export type StickyBarActions = ReturnType<
  (typeof stickyBarSlice.actions)[keyof typeof stickyBarSlice.actions]
>;
