// This file is made to fix a problem where global variables are considered local sometimes,
// generating a 'Variable is not defined' error when it should not.

// Player instance
if (!global.OnePlayer) {
  global.OnePlayer = undefined;
}

// Minimal player instance
if (!global.OnePlayerMinimal) {
  global.OnePlayerMinimal = undefined;
}
