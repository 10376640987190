export enum SettingQualityD2G {
  Medium = 'SD',
  High = 'HD',
  default = 'default',
}

export type D2GModalProps = {
  contentId?: string;
  idItem?: string;
  availabilityEndDate?: number;
  contentPathname?: string;
  downloadDate?: number;
  title?: string;
};
