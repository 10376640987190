import {
  HodorSdkConfig,
  getConfigWithFilteredFeatureToggles,
  getMergedConfigWithAuthenticate,
  authenticate as sdkAuthenticate,
} from '@canalplus/sdk-hodor';
import { ApiV2Authenticate } from '@dce-front/hodor-types';
import { markPerformance } from '../../../helpers/performance/markPerformance';
import { rewriteObjectUrls } from '../../../helpers/proxyUrls/proxyUrls';
import { getFirstLevelPageList } from '../../../server/helpers/authenticate-helpers';
import {
  serviceAuthenticateSuccess,
  setHeaderLevelPageList,
  setLocaleFromHodor,
} from '../../../store/slices/application';
import { getWhiteListedFeatureToggles } from '../Init/getWhiteListedFeatureToggles';
import { HodorCinematicParametersSpecific } from '../types';
import { getSdkAuthenticateParameters } from './getSdkAuthenticateParameters';

type PerformAuthenticateParameters = {
  configWithInit: HodorSdkConfig;
  isKidsProfile?: boolean;
  shouldCollectUserData?: boolean;
} & HodorCinematicParametersSpecific;

/**
 * - fetch `Authenticate` API
 * - transform the response to fit the Orange needs
 * - fetch `UserMenuExtended` API
 * - dispatch some values in redux store
 */
export async function performAuthenticate({
  configWithInit,
  request,
  store: argStore,
  isKidsProfile,
  shouldCollectUserData,
}: PerformAuthenticateParameters): Promise<{
  authenticateResponse: ApiV2Authenticate;
  hodorSdkConfig: HodorSdkConfig;
}> {
  const store = argStore || request.reduxStore.store;
  const state = store.getState();

  let authenticateResponse = await sdkAuthenticate(configWithInit, {
    ...getSdkAuthenticateParameters(
      request
        ? { request }
        : { store: argStore, isKidsProfile, shouldCollectUserData }
    ),
  });

  authenticateResponse = rewriteObjectUrls(authenticateResponse);

  markPerformance('hodor-authenticate-fetched', state);

  // Set first level page list from hodor arborescence
  const firstLevelPageList = getFirstLevelPageList(
    authenticateResponse.arborescence?.concat(
      authenticateResponse.userMenu || []
    )
  );

  store.dispatch(setHeaderLevelPageList(firstLevelPageList));

  // If hodor sends a locale, set it in store
  if (authenticateResponse.locale?.code) {
    store.dispatch(setLocaleFromHodor(authenticateResponse.locale.code));
  }

  const configWithAuthenticate = getMergedConfigWithAuthenticate(
    configWithInit,
    authenticateResponse
  );

  store.dispatch(
    serviceAuthenticateSuccess({
      ...authenticateResponse,
    })
  );

  const freshState = store.getState();

  const filteredConfig = getConfigWithFilteredFeatureToggles({
    config: configWithAuthenticate,
    whiteListedFeatureToggles: getWhiteListedFeatureToggles(freshState),
  });

  return { authenticateResponse, hodorSdkConfig: filteredConfig };
}
