import { trackSendEvent, trackSendPageView } from '@canalplus/ifc-onecore';
import {
  TrackingFormatted,
  formatTracking,
  getObjectKeys,
  initTracking as initializeTrackingTool,
  isClientSide,
} from '@canalplus/mycanal-commons';
import { OfferLocation } from '@canalplus/sdk-core';
import { ApiV2Context } from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import {
  DataLayerTracking,
  Tracking,
} from '@dce-front/hodor-types/api/v2/common/dto/tracking/definitions';
import { ApiV2TvodTracking } from '@dce-front/hodor-types/api/v2/common/dto/tvod/defintions';
import { ApiV2InitDataLayer } from '@dce-front/hodor-types/api/v2/me/init/definitions';
import {
  ApiV2PageTracking,
  ApiV2PageTrackingDataLayer,
} from '@dce-front/hodor-types/api/v2/page/dtos/definitions';
import {
  AppKey,
  Overlay,
  TrackingEnvironment,
} from '../../../config/application/types';
import { Moods } from '../../constants/mood';
import {
  ADDED_TO_PLAYLIST,
  REMOVED_FROM_PLAYLIST,
} from '../../constants/playlist';
import { FACEBOOK, TWITTER } from '../../constants/sharing';
import { ONGOING, PLAYLIST, RECOMMENDATIONS } from '../../constants/strates';
import type { ThemeColor } from '../../constants/themeColor';
import {
  type ProfileType,
  type SettingImageQualityValue,
  CHANGE_SETTING_IMAGE_QUALITY_NAME,
  CHANGE_SETTTING_D2G_QUALITY,
  ProfileEvents,
  TrackingEventName,
  WITHOUT_AD,
  WITH_AD,
} from '../../constants/tracking';
import { DownloadToGoTypeKeys } from '../../store/reducers/actions/DownloadToGo-actions';
import type { PlayerState } from '../../store/slices/player';
import { SecondaryActionType } from '../../templates/DetailV5/components/ActionLayout/SecondaryActions/types';
import type { SettingQualityD2G } from '../../templates/DownloadToGo/types';
import { isClient } from '../applicationContext/applicationContext-helper';
import { getPublicConfig } from '../config/config-helper';
import { injectScriptToDom } from '../injectToDom/injectToDom-helper';
import {
  AvatarTrackingData,
  IPlayInfoTracking,
  RawTracking,
  TealiumKey,
  TealiumKeySuffix,
  TealiumPrefix,
  tealiumZones,
} from './types';

/**
 * Allow to get tealium from appLocation
 * @param appLocation
 * @returns string | undefined
 */

export const getTealiumKey = (
  appLocation: OfferLocation = OfferLocation.fr
): string | undefined => {
  const publicConfig = getPublicConfig();
  const defaultLocale = publicConfig.defaultLocale.offerLocation;
  const prefix = publicConfig.TRACKING.OMNITURE.TEALIUM_KEY;
  const zones = Object.keys(tealiumZones) as TealiumKeySuffix[];

  if (prefix === TealiumPrefix.tvod) {
    return prefix;
  }

  if (appLocation === OfferLocation.pl) {
    return TealiumKey.poland;
  }

  // it and fr case
  if (appLocation === defaultLocale) {
    return `${prefix}-web`;
  }

  const tealiumZone = zones.find((zone) =>
    tealiumZones[zone].includes(appLocation)
  );

  return tealiumZone && `${prefix}-${tealiumZone}`;
};

/**
 * Returns a formatted string (',', ';', '&', replaced by '-' and '{', '}', '"' replaced by '')
 * @param {string} text
 * @returns {string}
 */
export const slugify = (text: string): string =>
  text.replace(/[,;&]/g, '-').replace(/[{}"]/g, '');

/**
 * Update the channel config for Pass at each page change
 * @param {object} tracking formatted tracking object
 */
export const handlePassOmnitureChannel = (
  tracking: TrackingFormatted<
    DataLayerTracking | ApiV2PageTrackingDataLayer | undefined
  >
): void => {
  if (!isClientSide() || !Object.keys(tracking).length) {
    return;
  }

  if (
    tracking.data?.channel &&
    tracking.data?.channel !== window.passOmnitureChannel
  ) {
    window.passOmnitureChannel = tracking.data.channel;
  }
};

/**
 * Add a `context_list_item_position` property to the trackingContext of each content of an array.
 * This property is used to track the position of contents in a strate.
 * @param  {Array<Object>}  [array=[]] - The array. For convenience a default empty array will be used if you provide `undefined`
 * @returns {array} A new array which contains updated elements
 */
export const mapContextListItemPosition = (array: any = []): any =>
  array.map((item: any, index: number) => {
    if (item.onClick && item.onClick.trackingContext) {
      return {
        ...item,
        onClick: {
          ...item.onClick,
          trackingContext: {
            ...item.onClick.trackingContext,
            context_list_item_position: index + 1,
          },
        },
      };
    }
    return item;
  });

/**
 * ## formatRawTracking
 * Function used to format the raw tracking object from Hodor
 * @param props contains the raw result of Hodor tracking
 * @returns formatted tracking object ready to be sent as an event
 */
const formatRawTracking = (props: RawTracking) => {
  const {
    abTestingPopulation,
    CTATrackingObj,
    error,
    favoritesEpgIds,
    isKids,
    tracking,
    initTracking,
    offerLocation,
    pageURL,
    segType,
    themeColor,
    version,
    trackingContext,
  } = props;

  const formattedTracking = formatTracking(tracking.dataLayer);

  handlePassOmnitureChannel(formattedTracking);

  const enhancedTracking = {
    ...formattedTracking,
    data: {
      ...formattedTracking.data,
      profile_kids: isKids.toString(),
      eVar35: abTestingPopulation,
      setting_theme: themeColor,
      ...(pageURL && { pageURL }),
      ...(error && { eVar14: slugify(error) }),
      ...(segType && { segment_type: segType }),
      ...(version && { app_version: version }),
      ...(trackingContext && {
        ...trackingContext,
        ...(trackingContext.contextType && {
          prop17: trackingContext.contextType,
        }),
        ...(trackingContext.contextDetail && {
          prop18: trackingContext.contextDetail,
        }),
      }),
      ...(offerLocation && { app_zone: offerLocation }),
      ...(favoritesEpgIds && {
        favorite_channels_list: favoritesEpgIds,
        favorite_channels_number: favoritesEpgIds.length.toString(),
      }),
      ...(CTATrackingObj &&
        Object.keys(CTATrackingObj).length && { ...CTATrackingObj }),
      ...(initTracking && initTracking),
    },
  } as const;

  return enhancedTracking;
};

/**
 * Format purchase info tracking to confirmation tvod funnel
 * @param rawTracking raw result of Hodor tracking
 */
const formatPurchaseTracking = (rawTracking: RawTracking) => {
  return {
    data: {
      ...(rawTracking.purchaseInfo && {
        purchase_id: rawTracking.purchaseInfo.purchaseId,
        product_category: rawTracking.purchaseInfo.productCategory,
        product_price: rawTracking.purchaseInfo.productPrice,
        payment_method: rawTracking.purchaseInfo.paymentMethod,
      }),
    },
  };
};

/**
 * Function in charge of calling the window.sendPageView
 * If the trackingtool is loaded the tracking will be sent
 * If not, it will be queued
 * @param tracking
 */
export const sendTrackingHitOnPage = (
  rawTracking: RawTracking,
  isTvDevice: boolean
): void => {
  if (!isClientSide() || !Object.keys(rawTracking.tracking || {}).length) {
    return;
  }

  const formattedRawTracking = formatRawTracking(rawTracking);
  const formattedPurchaseTracking = formatPurchaseTracking(rawTracking);

  sendPageViewTracking(
    {
      ...formattedRawTracking,
      data: {
        ...formattedRawTracking.data,
        ...formattedPurchaseTracking.data,
      },
    },
    isTvDevice
  );
};

/**
 * Compute eVar38 value from tracking info
 * @param  {string}  platform      Platform name of content played
 * @param  {string}  downloadToGoPlatform  downloadToGo Platform name
 * @param  {string}  videoType   Either 'Live' or 'ALD'
 * @param  {object}  infoTracking  Object containing information about tracking event from the player
 * @returns  {string}
 */
export const getEVar38Value = (
  platform: string,
  downloadToGoPlatform: string,
  videoType: string,
  infoTracking: IPlayInfoTracking
): string => {
  if (infoTracking.isTrailer) {
    return 'Regarder BA';
  }
  return platform === downloadToGoPlatform
    ? 'Télécharger'
    : `Regarder ${videoType}`;
};

export type SendProfileTrackingEventParameters = {
  activeProfiles: number;
  event: React.SyntheticEvent;
  isKids: boolean;
  profileType: ProfileType;
  themeColor: ThemeColor;
  type: ProfileEvents;
  avatarTrackingData?: AvatarTrackingData;
  offerLocation?: OfferLocation;
  pageURL?: string;
  profileId?: string;
  segType?: string;
  version?: string;
  isTvDevice?: boolean;
};

/**
 * Function in charge of calling window.sendEvent on profile creation/update/deletion/change
 * @param {object} event
 * @param {string} segType
 * @param {string} version
 * @param {string} pageURL
 */
export const sendProfileTrackingEvent = ({
  activeProfiles,
  event,
  isKids,
  offerLocation,
  pageURL,
  profileId,
  profileType,
  segType,
  themeColor,
  type,
  version,
  avatarTrackingData,
  isTvDevice,
}: SendProfileTrackingEventParameters): void => {
  if (!isClientSide() || !type) {
    return;
  }

  let name;
  let eventNb;

  switch (type) {
    case ProfileEvents.Create:
      name = 'create profile';
      eventNb = 'event27';
      break;
    case ProfileEvents.Change:
      name = 'change profile';
      eventNb = 'event28';
      break;
    case ProfileEvents.Edit:
      name = 'edit profile';
      eventNb = 'event29';
      break;
    case ProfileEvents.Delete:
      name = 'delete profile';
      eventNb = 'event30';
      break;
    case ProfileEvents.Select:
      name = 'select profile';
      eventNb = 'event31';
      break;
    default:
      break;
  }

  const profileTracking = {
    data: {
      events: eventNb,
      active_profiles: activeProfiles,
      setting_theme: themeColor,
      profile_id: profileId,
      profile_kids: isKids.toString(),
      profile_type_target: profileType,
      ...(avatarTrackingData && {
        profile_avatar_id: avatarTrackingData?.avatarId,
        profile_avatar_name: avatarTrackingData?.avatarName,
        profile_avatar_list_name: avatarTrackingData?.avatarListName,
      }),
      ...(segType && { segment_type: segType }),
      ...(version && { app_version: version }),
      ...(pageURL && { pageURL }),
      ...(offerLocation && { app_zone: offerLocation }),
    },
    event,
    name,
    type: 'custom',
  };

  if (isTvDevice) {
    trackSendEvent({
      ...profileTracking,
      event: event.type,
    }).catch(console.error);
  }

  window.sendEvent?.(profileTracking);
};

/**
 * Extract tracking context info from tracking object
 * @param {object} tracking formatted tracking object
 */
export const extractTrackingContext = (
  tracking: Tracking | ApiV2PageTracking
): any => {
  if (tracking) {
    return Object.keys(tracking)
      .filter((key) => key.includes('context'))
      .reduce((obj, key) => {
        obj[key] = tracking[key];
        return obj;
      }, {});
  }
};

/**
 * Function in charge to prepare the tracking object
 * to send when user play or download a content
 *
 * @param  {string}  platform               Platform name of content played
 * @param  {object}  infoTracking           Object containing information about tracking event from the player
 * @param  {boolean} isKids                 Current profile is kids or not
 * @param  {object}  tracking               Object containing information about tracking event
 * @param  {string}  playerAdsPath          Path to Player Ads
 * @param {number}   abTestingPopulation    abTestingPopulation (either 1 or 2)
 * @param {string}   segType                segment type from alerting
 * @param {string}   version                app version
 * @param {string}   pageURL                page URL
 * @param {string}   offerLocation            app location
 * @param {boolean}  startFromBeginning     whether the live program has started from beginning
 * @param {boolean}  isMultilive            whether we are in a multilive context
 * @param {array}    multiliveEpgIds        array of epgIds selected for multilive
 * @returns {object}                        tracking object with additionnal information about tracking event
 */
export const sendPlayTrackingEvent = ({
  abTestingPopulation = 1,
  infoTracking = {},
  initTracking,
  isKids,
  isMultilive = false,
  isStartOverAsReplayAvailable = false,
  multiliveEpgIds = [],
  offerLocation,
  pageURL,
  platform,
  playerAdsPath = null,
  segType,
  startFromBeginning = false,
  themeColor,
  tracking,
  trackingContext,
  version,
}: {
  abTestingPopulation?: number;
  infoTracking?: IPlayInfoTracking;
  isKids: boolean;
  isMultilive?: boolean;
  isStartOverAsReplayAvailable?: boolean;
  multiliveEpgIds?: number[];
  offerLocation?: OfferLocation;
  pageURL: string | undefined;
  platform?: string;
  playerAdsPath?: string | null;
  segType: string | undefined;
  startFromBeginning?: boolean;
  themeColor: ThemeColor;
  tracking: Tracking | ApiV2PageTracking;
  trackingContext?: PlayerState['trackingContext'];
  version: string | undefined;
  initTracking?: ApiV2InitDataLayer;
}): TrackingFormatted<object> | undefined => {
  if (!Object.keys(tracking).length) {
    return;
  }

  const publicConfig = getPublicConfig();
  const videoType =
    platform === publicConfig.PLAYER.PLATFORM.LIVE ? 'Live' : 'ALD';
  const downloadToGoPlatform =
    publicConfig.PLAYER.PLATFORM.DOWNLOADTOGO.toUpperCase();
  const eVar38 = platform
    ? getEVar38Value(platform, downloadToGoPlatform, videoType, infoTracking)
    : undefined;

  const pageNameSuffix: string[] = [];

  if (infoTracking.isTrailer) {
    pageNameSuffix.push(' - BA');
  }

  const extractedTrackingContext = extractTrackingContext(tracking);

  let trackingOnReadingProgram = formatTracking({
    ...trackingContext,
    ...extractedTrackingContext,
    ...tracking?.dataLayer,
    ...initTracking,
  });

  trackingOnReadingProgram.data.page_name = trackingOnReadingProgram.data
    .page_name
    ? `${trackingOnReadingProgram.data.page_name}${pageNameSuffix}`
    : undefined;

  handlePassOmnitureChannel(trackingOnReadingProgram);

  if (platform === publicConfig.PLAYER.PLATFORM.LIVE || isMultilive) {
    let liveType;
    if (startFromBeginning) {
      liveType = 'Startover';
    } else if (isStartOverAsReplayAvailable) {
      liveType = 'Startover as replay';
    } else if (isMultilive) {
      liveType = 'Multilive';
    } else {
      liveType = 'Live TV';
    }

    trackingOnReadingProgram = {
      data: {
        eVar35: abTestingPopulation,
        eVar37: videoType,
        events: 'event10,event17',
        ...trackingOnReadingProgram.data,
      },
      level1: liveType,
      name: 'Lecture Player',
    };
  } else {
    Object.keys(trackingOnReadingProgram.data).forEach((key) => {
      if (typeof trackingOnReadingProgram.data[key] === 'string') {
        trackingOnReadingProgram.data[key] = trackingOnReadingProgram.data[
          key
        ].replace(/Page/g, 'Lecture');
      }
    });
    if (infoTracking) {
      trackingOnReadingProgram = {
        data: {
          eVar35: abTestingPopulation,
          eVar37: videoType,
          events: 'event10,event17',
          prop20:
            infoTracking.title && infoTracking.subtitle
              ? `${slugify(infoTracking.title)} - ${infoTracking.subtitle}`
              : '',
          prop21:
            infoTracking.isNoPub || playerAdsPath === null
              ? WITHOUT_AD
              : WITH_AD,
          prop26: infoTracking.duration,
          ...trackingOnReadingProgram.data,
        },
        name: trackingOnReadingProgram.data.page_name,
      };

      if (playerAdsPath !== null) {
        const [prop23, prop24, prop25] = playerAdsPath.split('/');
        trackingOnReadingProgram = {
          data: {
            eVar35: abTestingPopulation,
            prop22: playerAdsPath,
            // if no slash in adPath, prop23 = prop22: no need to display it
            prop23: prop23 !== playerAdsPath ? prop23 : undefined,
            prop24,
            prop25,
            ...trackingOnReadingProgram.data,
          },
          name: trackingOnReadingProgram.data.page_name,
        };
      }
    }
  }
  const { contextType, contextDetail } = trackingOnReadingProgram.data;
  trackingOnReadingProgram = {
    ...trackingOnReadingProgram,
    data: {
      ...trackingOnReadingProgram.data,
      ...(segType && { segment_type: segType }),
      ...(version && { app_version: version }),
      ...(offerLocation && { app_zone: offerLocation }),
      ...(pageURL && { pageURL }),
      ...(eVar38 && { eVar38 }),
      profile_kids: isKids.toString(),
      setting_theme: themeColor,
      ...(contextType && { prop17: contextType }),
      ...(contextDetail && { prop18: contextDetail }),
      ...(multiliveEpgIds.length && {
        multilive_channels_list: multiliveEpgIds,
        multilive_streams_number: multiliveEpgIds.length.toString(),
      }),
    },
  };

  window.sendPageView?.(trackingOnReadingProgram);

  return trackingOnReadingProgram;
};

/**
 * Function in charge to call the window.sendPageView for the Search component.
 *
 * If tracking bundle is loaded, it will be sent.\
 * If not, it will be queued.
 */
export const sendTrackingHitOnSearch = ({
  abTestingPopulation,
  isKids,
  isTvDevice = false,
  offerLocation,
  pageURL,
  segType,
  themeColor,
  tracking,
  resultsLength,
  version,
}: RawTracking & {
  isTvDevice?: boolean;
  resultsLength?: number;
}): void => {
  if (!isClientSide()) {
    return;
  }

  const formattedTracking = formatTracking({
    eVar35: abTestingPopulation,
    profile_kids: isKids.toString(),
    level1: 'Recherche',
    ...(themeColor && { setting_theme: themeColor }),
    ...(segType && { segment_type: segType }),
    ...(version && { app_version: version }),
    ...(pageURL && { pageURL }),
    ...(offerLocation && { app_zone: offerLocation }),
    ...(tracking.dataLayer && tracking.dataLayer),
  });

  const searchTracking = {
    ...formattedTracking,
    search: {
      count: resultsLength,
      ...(tracking.dataLayer &&
        'search_query' in tracking.dataLayer && {
          keyword: tracking.dataLayer.search_query,
        }),
    },
  };

  // Because some tracking values from Hodor are wrong
  // we need to change these values from the tracking object before sendTrackingHitOnSearch
  Object.keys(searchTracking.data).forEach((key) => {
    if (typeof searchTracking.data[key] === 'string' && resultsLength === 0) {
      searchTracking.data[key] = searchTracking.data[key].replace(
        /Resultats/g,
        'Pas de resultat'
      );
    }
  });

  sendPageViewTracking(searchTracking, isTvDevice);
};

export const findCurrentTrackingEnvironment = (): TrackingEnvironment => {
  const { ENVIRONMENT } = getPublicConfig().TRACKING.OMNITURE;
  const host = window.location.host;
  const envKeyFounded =
    getObjectKeys(ENVIRONMENT).find((env) => host.includes(env)) || 'default';

  return ENVIRONMENT[envKeyFounded];
};

/**
 * Call the script for the tracking with params
 * One can even give it a callback to perform functions once it's finished.
 * @param {String} locale
 * @param {Boolean} disableCnil
 * @param {String} trackingLibraryUrl
 * @param {Boolean} isTvDevice
 * @returns {bool}
 */
export const loadTrackingScripts = (
  appKey?: AppKey,
  locale?: OfferLocation,
  disableCnil?: boolean,
  trackingLibraryUrl?: string,
  isTvDevice?: boolean
): boolean => {
  const publicConfig = getPublicConfig();

  // Do not include the tracking script if we are on TV as it is already added by R9
  if (!isClient() || isTvDevice || publicConfig.mock) {
    return false;
  }

  const currentTrackingEnv = findCurrentTrackingEnvironment();

  const brand = appKey || publicConfig.api.hodor.defaultAppKey;
  const attributes = {
    'data-channel': publicConfig.TRACKING.OMNITURE.CHANNEL,
    'data-dtm-key': publicConfig.TRACKING.OMNITURE.DTM_KEY,
    'data-filter': publicConfig.TRACKING.OMNITURE.FILTER,
    'data-reportsuite': publicConfig.TRACKING.OMNITURE.OMNITURE_REPORTSUITE,
    'data-tealium-key': getTealiumKey(locale),
    'data-brand': brand,
    'data-zone': locale,
    'data-environment': currentTrackingEnv,
    id: 'tt-script',
  };

  if (!disableCnil) {
    attributes['data-include-cnil'] = '';
  }
  injectScriptToDom(
    trackingLibraryUrl || publicConfig.TRACKING.OMNITURE.BUNDLE,
    { attributes }
  );
  initializeTrackingTool();

  return true;
};

/**
 * Function in charge of preparing the tracking object
 * to send when user updates mood, playlist or reco content
 *
 * @param  {object}  tracking   Object containing information about tracking event
 * @param  {string}  type   Type of Event
 * @param  {number}  abTestingPopulation   abTestingPopulation (either 1 or 2)
 * @param  {string}  segType
 * @param  {string}  version
 * @param  {string}  pageURL
 */
export const sendTrackingOnCTAEvent = ({
  abTestingPopulation,
  isKids,
  offerLocation,
  pageURL,
  segType,
  themeColor,
  tracking,
  type,
  version,
  isTvDevice,
}: {
  abTestingPopulation?: number;
  isKids: boolean;
  offerLocation?: string;
  pageURL?: string;
  segType?: string | undefined;
  themeColor?: ThemeColor;
  tracking: Tracking | ApiV2PageTracking;
  type: string;
  version?: string | undefined;
  isTvDevice: boolean;
}): void => {
  let name: string;
  let eVar33: string;

  switch (type) {
    case RECOMMENDATIONS:
      eVar33 = 'Delete not interested';
      name = 'Remove from reco';
      break;
    case ONGOING:
      eVar33 = 'Delete ongoing';
      name = 'Remove from ongoing';
      break;
    case PLAYLIST:
    case REMOVED_FROM_PLAYLIST:
      eVar33 = 'Delete playlist';
      name = 'Remove from playlist';
      break;
    case ADDED_TO_PLAYLIST:
      eVar33 = 'Playlist';
      name = 'Add to playlist';
      break;
    case DownloadToGoTypeKeys.OPEN_DOWNLOAD_TO_GO:
      eVar33 = 'download';
      name = 'download';
      break;
    case SecondaryActionType.Recording:
      eVar33 = type;
      name = 'Record';
      break;
    case Moods.Neutral:
      eVar33 = type.charAt(0).toUpperCase() + type.slice(1);
      name = 'Rate neutral';
      break;
    case Moods.Like:
      eVar33 = type.charAt(0).toUpperCase() + type.slice(1);
      name = 'Rate like';
      break;
    case Moods.Dislike:
      eVar33 = type.charAt(0).toUpperCase() + type.slice(1);
      name = 'Rate dislike';
      break;
    case FACEBOOK:
      eVar33 = 'Sharing Facebook';
      name = 'Share';
      break;
    case TWITTER:
      eVar33 = 'Sharing Twitter';
      name = 'Share';
      break;
    default:
      eVar33 = type;
      name = 'CTA event';
  }
  const CTATrackingObj = {
    eVar33,
    events: 'event9',
  };

  const trackingContext = extractTrackingContext(tracking);

  const formattedTrackingObj = formatTracking({
    ...trackingContext,
    ...tracking.dataLayer,
  });

  const CTATracking = {
    data: {
      eVar35: abTestingPopulation,
      profile_kids: isKids.toString(),
      setting_theme: themeColor,
      ...formattedTrackingObj.data,
      ...(segType && { segment_type: segType }),
      ...(version && { app_version: version }),
      ...(pageURL && { pageURL }),
      ...(offerLocation && { app_zone: offerLocation }),
      ...CTATrackingObj,
    },
    name,
  };

  if (isTvDevice) {
    trackSendEvent(CTATracking).catch(console.error);
  }

  return window.sendEvent?.(CTATracking);
};

export type SendDeeplinkTrackingEventOptions = {
  isKids: boolean;
  name: string;
  offerLocation: OfferLocation;
  tracking: (DataLayerTracking | ApiV2PageTrackingDataLayer) &
    (ApiV2Context | undefined);
  version: string;
  abTestingPopulation?: number;
  contentID?: string;
  pageURL?: string;
  segType?: string;
  themeColor?: ThemeColor;
  urlWebsite?: string;
  isTvDevice?: boolean;
};

/**
 * Function in charge of calling `window.sendEvent` when opening external site\
 * in new tab (=deeplink)
 *
 * @param abTestingPopulation
 * @param isKids
 * @param tracking
 * @param contentID
 * @param name
 * @param segType
 * @param version
 * @param pageURL
 */
export const sendDeeplinkTrackingEvent = ({
  abTestingPopulation,
  isKids,
  contentID,
  name,
  offerLocation,
  pageURL,
  segType,
  themeColor,
  tracking,
  urlWebsite,
  version,
  isTvDevice,
}: SendDeeplinkTrackingEventOptions): void => {
  if (!isClientSide() || !Object.keys(tracking).length || !name) {
    return;
  }

  const formattedTrackingObj = formatTracking(tracking);

  const deeplinkTracking = {
    data: {
      eVar35: abTestingPopulation,
      profile_kids: isKids.toString(),
      setting_theme: themeColor,
      ...formattedTrackingObj.data,
      ...(contentID && { content_id: contentID }),
      ...(offerLocation && { app_zone: offerLocation }),
      ...(pageURL && { pageURL }),
      ...(segType && { segment_type: segType }),
      ...(urlWebsite && { Service_url: urlWebsite }),
      ...(version && { app_version: version }),
    },
    name,
  };

  if (isTvDevice) {
    trackSendEvent({ ...deeplinkTracking, event: name }).catch(console.error);
  }

  window.sendEvent?.(deeplinkTracking);
};

export type NotificationTrackingEventName =
  | TrackingEventName.ViewAlert
  | TrackingEventName.CloseAlert;

/**
 * Function in charge of calling window.sendEvent when calling notifications service
 * @param {string} eventName
 * @param {string} alertType
 * @param {string} segType
 * @param {string} version
 * @param {string} pageURL
 * @param {string} offerLocation
 */
export const sendTrackingHitOnNotification = ({
  eventName = TrackingEventName.ViewAlert,
  alertType,
  isKids,
  offerLocation,
  pageURL,
  segType,
  themeColor,
  version,
}: {
  eventName?: NotificationTrackingEventName;
  alertType?: string;
  isKids: boolean;
  offerLocation?: string;
  pageURL: string;
  segType?: string;
  themeColor: ThemeColor;
  version: string | undefined;
}): void => {
  if (!isClientSide() || (!segType && !alertType)) {
    return;
  }

  const notificationTracking = {
    data: {
      events: eventName,
      profile_kids: isKids.toString(),
      setting_theme: themeColor,
      ...(alertType && { alert_type: alertType }),
      ...(segType && { segment_type: segType }),
      ...(version && { app_version: version }),
      ...(pageURL && { pageURL }),
      ...(offerLocation && { app_zone: offerLocation }),
    },
    name: eventName,
  };

  window.sendEvent?.(notificationTracking);
};

export type LaunchingPlayerError = {
  category: string;
  code: string;
  details: { message: string };
};

/**
 * Function in charge of calling window.sendEvent when errors occur when launching the player on web
 * The parameters are attributes of the destructured error.data object that the player's onError event returns
 */
export const sendLaunchingPlayerError = ({
  category,
  code,
  details: { message },
}: LaunchingPlayerError): void => {
  if (!isClientSide() || !code || !category || !message) {
    return;
  }

  const ERROR_MESSAGE_PREFIX = 'OnePlayer_error';
  const ERROR_MESSAGE_FORMAT = `${ERROR_MESSAGE_PREFIX}_[${category}]_[${code}]_[${message}]`;

  window.sendEvent?.({
    name: TrackingEventName.ViewError,
    data: {
      error_message: ERROR_MESSAGE_FORMAT,
    },
  });
};

export const sendSettingImageQualityChangeTracking = (
  qualityImageValue: SettingImageQualityValue
): void => {
  if (!isClientSide() || !qualityImageValue) {
    return;
  }

  window.sendEvent?.({
    data: {
      setting_name: CHANGE_SETTING_IMAGE_QUALITY_NAME,
      setting_value: qualityImageValue,
    },
    name: TrackingEventName.ChangeSetting,
  });
};

export const sendSettingD2GQualityChange = (
  qualityD2G: SettingQualityD2G
): void => {
  if (!isClientSide() || !qualityD2G) {
    return;
  }

  window.sendEvent?.({
    data: {
      setting: CHANGE_SETTTING_D2G_QUALITY,
      setting_D2GQuality: qualityD2G,
    },
    name: TrackingEventName.ChangeSetting,
  });
};

export const sendPayVod = (
  payVodTrackingDataLayer: ApiV2TvodTracking,
  isTVDisplay: boolean = false
): void => {
  if (!payVodTrackingDataLayer) {
    return;
  }

  if (isTVDisplay) {
    trackSendEvent({
      data: {
        ...payVodTrackingDataLayer.dataLayer,
      },
      name: payVodTrackingDataLayer?.dataLayer?.page_name || 'pay vod',
    }).catch(console.error);
  }

  window.sendEvent?.({
    name: payVodTrackingDataLayer?.dataLayer?.page_name || 'pay vod',
    data: payVodTrackingDataLayer.dataLayer,
  });
};

/**
 * Function in charge to call `window.sendPageView`\
 * or `trackSendPageView` from Ifc
 * - If FormattedDataLayer bundle is loaded the tracking will be sent
 * - If not, it will be queued
 *
 * @param tracking The `FormattedDataLayer` tracking object to send
 * @param isTVDisplay Whether we are on a TV display or not
 */
const sendPageViewTracking = (
  formattedDataLayer: TrackingFormatted,
  isTVDisplay: boolean = false
): void => {
  if (isTVDisplay) {
    trackSendPageView(formattedDataLayer).catch(console.error);
  }
  // Defer `sendPageView` execution on the next tick
  // because `initTracking` may not have finished
  setTimeout(() => {
    window.sendPageView?.(formattedDataLayer);
  }, 0);
};

export const overlayTrackingName = {
  [Overlay.TIM]: 'TIMVISION',
  [Overlay.TVODStandalone]: 'Vod',
  [Overlay.MyCanal]: 'myCANAL',
} as const satisfies Record<Overlay, string>;
