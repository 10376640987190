import { jsonStorageFactory } from '../jsonStorageFactory/jsonStorageFactory-helper';
import StorageMock from '../storageMock/storageMock-helper';

/**
 * @name checkLocalStorageSupport
 * @description typeof localStorage !== 'undefined'
 * wasn't covering the half supported private mode navigation
 */
const checkLocalStorageSupport = (): boolean => {
  try {
    return 'localStorage' in window && window.localStorage !== null;
  } catch (e) {
    return false;
  }
};

/**
 * Instance of DedMyCanal~JSONStorage using localStorage as backend
 * In SSR or if localStorage is not defined, use a stub.
 */
export const JsonLocalStorage = jsonStorageFactory(
  checkLocalStorageSupport() ? localStorage : new StorageMock()
);
