import { getProfile } from '@canalplus/ifc-onecore';
import { isClientSide } from '@canalplus/mycanal-commons';
import { getPassToken } from '../../store/slices/user';
import {
  passTokenSelector,
  waitForPassFinishSelector,
} from '../../store/slices/user-selectors';

/**
 * initializewaitForPassToken
 *
 * Add an waitForPassToken function to the window object
 * to allow pass token verification through the application without
 * using waitForPassJSON
 * @param  {object}  store
 * @returns {bool} true if token was immediatly available, false otherwise
 */
export const initializewaitForPassToken = (
  store: Redux.CustomStore
): boolean => {
  if (!isClientSide()) {
    return false;
  }

  // We set callback here to use it in the closure of waitForPassToken and unsubscribe
  // to be able to store the callbacks and call them when the token is finally retrieved
  let callbacks: any[] = [];

  (window as any).waitForPassToken = async (callback) => {
    const state = store.getState();
    const token = passTokenSelector(state);

    // For orange devices we don't use pass directly but instead rely on one-core to retrieve the
    // pass token
    // TODO: maybe we can do that for all tv devices ?
    if ($_BUILD_RENDERMODE_CSR) {
      const profile = await getProfile();
      store.dispatch(getPassToken(profile.passToken || ''));
      callback(passTokenSelector(store.getState()));
      return;
    }

    const waitForPassFinish = waitForPassFinishSelector(state);

    // If the pass token has already been retrieved, we call the callback with the token
    if (waitForPassFinish) {
      callback(token);
      return true;
    }
    // If not we add the callback to the list of callbacks to be called
    callbacks.push(callback);
    return false;
  };

  const unsubscribe = store.subscribe(() => {
    const state = store.getState();
    const waitForPassFinish = waitForPassFinishSelector(state);
    const token = passTokenSelector(state);

    if (waitForPassFinish) {
      unsubscribe();
      // We call every call back in the array and then set it to an empty array to avoid any unwanted call
      callbacks.map((fn) => fn(token));
      callbacks = [];
    }
  });

  return true;
};

/**
 * Promisified version of waitForPassToken
 * @return {Promise} - Resolves once waitForPassToken is done with the token
 */
export function waitForPassTokenAsync(): Promise<string> {
  return new Promise((resolve, reject) => {
    try {
      (window as any).waitForPassToken((token) =>
        token ? resolve(token) : reject()
      );
    } catch (e) {
      reject(e);
    }
  });
}
