import { isSomeEnum } from '@canalplus/mycanal-commons';

export enum ResizeMode {
  RESIZE_720P = '720p',
  RESIZE_AUTO = 'auto',
}

// if you change it, you have to change the string in css too
export const ResizeViewportMapping = {
  [ResizeMode.RESIZE_720P]: 'resize resize720p',
};

export const isResizeMode = isSomeEnum(ResizeMode);
