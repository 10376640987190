import { DisplayMode } from '@canalplus/mycanal-commons';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { JsonLocalStorage } from '../../helpers/jsonLocalStorage/jsonLocalStorage-helper';

export type UiState = {
  noScroll: boolean;
  displayMode: DisplayMode;
  isEmbeddedVideoFullscreenOpened: boolean;
  screenWidth: number;
  showSummaryEpisodes: boolean;
};

export const initialState: UiState = {
  noScroll: false,
  displayMode: DisplayMode.WINDOWED,
  isEmbeddedVideoFullscreenOpened: false,
  screenWidth: 300,
  showSummaryEpisodes: false,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    /** Toggles page scrolling behind modals */
    blockAppScroll(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        noScroll: action.payload,
      };
    },

    /** Sets various display modes for the player */
    setDisplayMode(state, action: PayloadAction<DisplayMode>) {
      return {
        ...state,
        displayMode: action.payload,
      };
    },

    /** Update screen size in state when resizing */
    screenResize(state, action: PayloadAction<number>) {
      // TODO handle persisted parameters throughout the whole app with a special
      // middleware like the one created for the cache
      // we set showSummaryEpisodes here because SCREEN_RESIZE is always called at the loading of the page
      const showSummaryEpisodes =
        JsonLocalStorage.get<boolean>('mycanal::ui::showSummaryEpisodes') ||
        false;

      return {
        ...state,
        screenWidth: action.payload,
        showSummaryEpisodes,
      };
    },

    /** Sets if fullscreen of an embedded video is opened */
    setIsEmbeddedVideoFullscreenOpened(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isEmbeddedVideoFullscreenOpened: action.payload,
      };
    },
  },
});

export const {
  blockAppScroll,
  setDisplayMode,
  setIsEmbeddedVideoFullscreenOpened,
  screenResize,
} = uiSlice.actions;

export const uiReducer = uiSlice.reducer;

export type UiActions = ReturnType<
  (typeof uiSlice.actions)[keyof typeof uiSlice.actions]
>;
