export enum CookieKey {
  AbTestingPopulation = 'abTestingPopulation',

  /** deviceId shape: **base:isAuthenticated:trackingKeyId** */
  DeviceId = 'deviceId',

  HodorKey = 'hodorKey',

  /** Stored as a string */
  IsKidsProfile = 'isKidsProfile',

  Notification = 'notification',
  Offerzone = 'offerzone',
  PassId = 'passId',

  /** Legacy passId */
  PPassToken = 'p_pass_token',

  /** Stored as a string */
  ProfileId = 'profileId',

  SessionId = 'sessionId',
  Recommandations = 'recommandations',
  TokenCMS = 'tokenCMS',
  WhoIsWatchingDisabled = 'whoIsWatchingDisabled',
  WhoIsWatchingSession = 'whoIsWatchingSession',
}

/** Duration in days for the deviceId cookie is 395 days = 13 months */
export const DEVICE_ID_COOKIE_DURATION = 395;
