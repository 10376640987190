import { resetAdultBrowsing, updateAdultActive } from '../slices/adult';
import { requestTemplate } from '../slices/page';

/**
 * This middleware is in charge of detecting if the next
 * content will be adult or not. Depending on the case, it
 * triggers on and off the adult disclaimer
 */
export const adultMiddleware: Redux.Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    const { adult } = getState();

    if (requestTemplate.match(action)) {
      if (action.payload.mainOnClick?.adult) {
        // ...and Adult is not active yet, we turn it on
        if (!adult.active) {
          dispatch(updateAdultActive());
        }
      } else if (adult.active) {
        // If we are not on an adult page...
        // ...and Adult is active, we turn it off
        dispatch(resetAdultBrowsing());
      } else if (!adult.active && adult.adultBrowsing) {
        // If we are not on adult live Grid...
        // ...active is false and adultBrowsing is true , we turn it off
        dispatch(resetAdultBrowsing());
      }
    }

    return next(action);
  };
