import { ThemeColor } from '../constants/themeColor';

export enum ApplicationTheme {
  Default = 'default',
  Tvod = 'tvod',
  Tim = 'telecomitalia',
}

export const Dark = ThemeColor.Dark;
export const Light = ThemeColor.Light;
