import type { IMeta } from '@canalplus/mycanal-sdk';
import type { OfferLocation } from '@canalplus/sdk-core';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { AppKey } from '../../../config/application/types';
import type { ImmersiveMeta } from '../../components/Immersive/types';
import { buildUpdatedMeta } from '../../helpers/metaTags/metaTags-helper';

export type MetaState = ImmersiveMeta;

const metaSlice = createSlice({
  name: 'meta',
  initialState: {},
  reducers: {
    setMeta(
      _state,
      action: PayloadAction<{
        meta: IMeta;
        offerLocation: OfferLocation;
        appKey?: AppKey;
      }>
    ): ImmersiveMeta {
      return buildUpdatedMeta(action.payload);
    },
  },
});

export const { setMeta } = metaSlice.actions;

export const metaReducer = metaSlice.reducer;

export type MetaActions = ReturnType<
  (typeof metaSlice.actions)[keyof typeof metaSlice.actions]
>;
