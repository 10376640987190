import { setPersoUpdated } from '../slices/immersive';
import { startPlayer } from '../slices/player';

/**
 * Handles the side effects for contentGrid perso pages
 */
export const personalStratesMiddleware: Redux.Middleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (startPlayer.match(action)) {
      dispatch(setPersoUpdated(true));
    }

    return next(action);
  };
