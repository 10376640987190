import { setCookie } from '@canalplus/mycanal-commons';
import { getSessionIdCookieValue } from '@canalplus/sdk-pass';
import Cookies from 'js-cookie';
import { CookieKey } from '../../constants/cookie';

export const setSessionIdCookie = (): void => {
  const currentSessionId = Cookies.get(CookieKey.SessionId);

  if (!currentSessionId) {
    setCookie(CookieKey.SessionId, getSessionIdCookieValue(), {
      expires: 1 / 48, // 30 minutes
    });
  }
};
