import { isClientSide } from '@canalplus/mycanal-commons';
import { SettingImageQualityValue } from '../../constants/tracking';
import { removeAllProfiles } from '../../services/profiles/profileStorageService';
import {
  overrideSettings,
  resetWhoIsWatchingSettings,
} from '../../store/slices/application';
import { settingsSelector } from '../../store/slices/application-selectors';
import type { ApplicationStateSettings } from '../../store/slices/application-types';
import { setReco, type userChangeSettings } from '../../store/slices/user';
import { JsonLocalStorage } from '../jsonLocalStorage/jsonLocalStorage-helper';
import Logger from '../logger/logger-helper';
import { removeAllCookiesWhoIsWatching } from '../whoIsWatching/cookieHelper';

export const localStorageKey = 'mycanal::application::settingCache';

/**
 * Retrieve settings stored in localStorage and pass it
 * to the store
 *
 * @param  {object}  [store]  redux store
 */
export const overrideSettingsWithCache = (store: Redux.CustomStore): void => {
  const cacheFeatureToggle = JsonLocalStorage.get(localStorageKey);
  if (cacheFeatureToggle instanceof Object) {
    store.dispatch(overrideSettings(cacheFeatureToggle));
  }
};

/**
 * Add two functions to the window object allowing to override
 * the current application settings or set it back to its original state
 *
 * @param  {object}  [store]  redux store
 */
export const initializeManualSettingsOverride = (
  store: Redux.CustomStore
): void => {
  const state = store.getState();
  const initialSetting = settingsSelector(state);
  overrideSettingsWithCache(store);

  if (!isClientSide()) {
    return;
  }

  (window as any).overrideSetting = (setting, value) => {
    const settings = settingsSelector(state);

    if (value === undefined || typeof value !== typeof settings[setting]) {
      Logger.warn(
        `The type of the new value for ${setting} does not match its previous one`
      );
    }

    const cache = JsonLocalStorage.get(localStorageKey) || {};
    store.dispatch(overrideSettings({ [setting]: value }));
    JsonLocalStorage.set(localStorageKey, { ...cache, [setting]: value });
    return true;
  };

  (window as any).cleanSettingOverride = () => {
    store.dispatch(overrideSettings({ ...initialSetting }));
    JsonLocalStorage.remove(localStorageKey);
  };
};

export const getLocalStorageSettings =
  (): Partial<ApplicationStateSettings> | null =>
    JsonLocalStorage.get(localStorageKey);

export const setLocalStorageSettings = (
  action: ReturnType<typeof userChangeSettings>
): void => {
  JsonLocalStorage.set(localStorageKey, action.payload);
};

/**
 * Transform the image quality in percentage in a `SettingImageQualityValue` enum
 * Used to send tracked data
 *
 * @param imageQualityPercentage The image quality in percentage to transform
 * @param minValue The value in percentage of the lowest quality image
 * @param maxValue The value in percentage of the higher quality image
 * @returns The current image quality in `SettingImageQualityValue`
 */
export const getImageQualityValue = (
  imageQualityPercentage: number,
  minValue: number,
  maxValue: number
): SettingImageQualityValue => {
  if (imageQualityPercentage === minValue) {
    return SettingImageQualityValue.Low;
  }

  if (imageQualityPercentage === maxValue) {
    return SettingImageQualityValue.High;
  }

  return SettingImageQualityValue.Medium;
};

/**
 * if user unsubscribe "Recommendation et personalization", the profile UI is disabled and we clean the profiles cookies
 * And we have to remove all cookies WhoIsWatching (WhoIsWatching is disabled if no reco).
 * It's important for cache akamai because it manage the cache of whoIsWatching with this cookie
 */
export const handleUnsubscribeRecommandation = (
  dispatch: Redux.Dispatch
): void => {
  // Update reco in store. It's important for hook useWhoIsWatching to not create session cookie when leave the app
  dispatch(setReco(false));

  // reset state WhoIsWaatching settings
  dispatch(resetWhoIsWatchingSettings());

  removeAllCookiesWhoIsWatching();
  removeAllProfiles();
};
