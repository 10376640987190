import { adultMiddleware } from './Adult-middleware';
import { downloadtoGoMiddleware } from './DownloadToGo-middleware';
import { externalServicesMiddleware } from './ExternalServices-middleware';
import { fetchInitLiveTVMiddleware } from './FetchInitLiveTV-middleware';
import { oneCoreSyncMiddleware } from './OneCoreSync-middleware';
import { personalStratesMiddleware } from './PersonalStrates-middleware';
import { playerInstanceMiddleware } from './PlayerInstance-middleware';
import { purchaseCodeMiddleware } from './PurchaseCode-middleware';
import { settingsMiddleware } from './Settings-middleware';
import { trackingMiddleware } from './Tracking-middleware';
import { transformUrlMiddleware } from './TransformUrl-middleware';
import { uiMiddleware } from './Ui-middleware';

export const middleware = [
  adultMiddleware,
  downloadtoGoMiddleware,
  externalServicesMiddleware,
  personalStratesMiddleware,
  playerInstanceMiddleware,
  purchaseCodeMiddleware,
  settingsMiddleware,
  trackingMiddleware,
  transformUrlMiddleware,
  uiMiddleware,
  fetchInitLiveTVMiddleware,
  oneCoreSyncMiddleware,
] as const;
