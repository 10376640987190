import { Tracking } from '@dce-front/hodor-types/api/v2/common/dto/tracking/definitions';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { closeImmersive } from './immersive';
import type { requestTemplate } from './page';

export type ErrorState = {
  template?: {
    displayName?: Routing.IOneDiscoveryOnClick['displayName'];
    displayTemplate?: Routing.IOneDiscoveryOnClick['displayTemplate'];
    immersive?: boolean;
    message?: string;
    path?: Routing.IOneDiscoveryOnClick['path'];
    pathname?: string;
    status?: number;
    text?: string;
    title?: string;
    tracking?: Tracking;
  };
  settings?: string;
};

const initialState: ErrorState = {};

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    errorTemplate(
      state,
      action: PayloadAction<NonNullable<ErrorState['template']>>
    ) {
      return {
        ...state,
        template: {
          ...action.payload,
        },
      };
    },

    errorSettingsUpdate(state, action: PayloadAction<string>) {
      return {
        ...state,
        settings: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        'page/requestTemplate' satisfies typeof requestTemplate.type,
        () => initialState
      )
      .addCase(
        'immersive/closeImmersive' satisfies typeof closeImmersive.type,
        () => initialState
      );
  },
});

export const { errorTemplate, errorSettingsUpdate } = errorSlice.actions;

export const errorReducer = errorSlice.reducer;

export type ErrorActions = ReturnType<
  (typeof errorSlice.actions)[keyof typeof errorSlice.actions]
>;
