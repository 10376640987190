const HISTORY_ACTION_POP = 'POP';
const HISTORY_ACTION_PUSH = 'PUSH';
const LOCATION_STATE = 'state';
const ON_CLICK = 'onClick';
const PATH_ERROR = '/error';
const HOMEPAGE_TV_PATH = '/buffer/?display=tv';

const DISPLAY_TEMPLATE = 'displayTemplate';
const CONTEXT = 'context';
const DISPLAY_NAME = 'displayName';
const PATH = 'path';
const ADULT = 'adult';
const URL_ADULT = 'URLPage';
const URL_PAGE = 'URLPage';
const TRACKING_CONTEXT = 'trackingContext';
const EXTERNAL_SITE = 'externalSite';

const ON_CLICK_DISPLAY_TEMPLATE = `${ON_CLICK}.${DISPLAY_TEMPLATE}`;
const ON_CLICK_CONTEXT = `${ON_CLICK}.${CONTEXT}`;
const ON_CLICK_DISPLAY_NAME = `${ON_CLICK}.${DISPLAY_NAME}`;
const ON_CLICK_PATH = `${ON_CLICK}.${PATH}`;
const ON_CLICK_ADULT = `${ON_CLICK}.${ADULT}`;
const ON_CLICK_URL_PAGE = `${ON_CLICK}.${URL_ADULT}`;
const ON_CLICK_TRACKING_CONTEXT = `${ON_CLICK}.${TRACKING_CONTEXT}`;

export default {
  ADULT,
  CONTEXT,
  DISPLAY_NAME,
  DISPLAY_TEMPLATE,
  EXTERNAL_SITE,
  HOMEPAGE_TV_PATH,
  HISTORY_ACTION_POP,
  HISTORY_ACTION_PUSH,
  LOCATION_STATE,
  ON_CLICK,
  ON_CLICK_ADULT,
  ON_CLICK_CONTEXT,
  ON_CLICK_DISPLAY_NAME,
  ON_CLICK_DISPLAY_TEMPLATE,
  ON_CLICK_PATH,
  ON_CLICK_TRACKING_CONTEXT,
  ON_CLICK_URL_PAGE,
  PATH,
  PATH_ERROR,
  TRACKING_CONTEXT,
  URL_ADULT,
  URL_PAGE,
};
