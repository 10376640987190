import { BusinessTypes } from '@canalplus/oneplayer-constants';
import {
  ILiveChannel,
  IParsedChannels,
  IRawLiveChannel,
  IRawMulticastUrls,
  IStreamUrls,
} from '@canalplus/oneplayer-types';

import { logger } from '../logger';
import { isEmptyObject, isNullOrUndefined } from '../object';
import removeDiacritics from '../string/removeDiacritics';
import { template } from '../template';

const {
  LIVE_TV_GROUP_TYPES: { NON_AUTHORIZED },
} = BusinessTypes;

/**
 * TODO: This should be done backend side.
 * On certain devices (Samsung TV) we want to show the users the nonAuthorizedChannel (groupType = 2)
 * But this groupType currently contains the channel Canal+ for prospective clients (epgId = 601)
 * Which would result in a duplicate Canal+ channel for premium users (301 and 601)
 * So we want to filter this channel here
 * @param channel IRawLiveChannel
 * @param groupType string
 * @returns boolean
 */
const isDuplicateCanalChannelInUnauthorizedGroup = (
  channel: IRawLiveChannel,
  groupType: string,
): boolean => {
  return (
    (channel.EpgId === '601' || channel.EpgId === '301') &&
    groupType === NON_AUTHORIZED.toString()
  );
};

/**
 * parse multicast urls (usefull especially for 'sfr' target)
 * @param rawMulticastUrls raw multicast url received from initlivetv call
 * @returns multicast load video options
 */
function parseMulticastUrls(
  rawMulticastUrls: IRawMulticastUrls[],
): IStreamUrls['Multicast'] | null {
  const multicast: IStreamUrls['Multicast'] = {};
  rawMulticastUrls.forEach((multicastUrl) => {
    if (multicastUrl.Type === 'SD') {
      multicast.sd = {
        Ip: multicastUrl.IP,
        Port: Number(multicastUrl.Port),
        Sid: Number(multicastUrl.Sid),
      };
    } else if (multicastUrl.Type === 'HD') {
      multicast.hd = {
        Ip: multicastUrl.IP,
        Port: Number(multicastUrl.Port),
        Sid: Number(multicastUrl.Sid),
      };
    } else if (multicastUrl.Type === 'UHD') {
      multicast.uhd = {
        Ip: multicastUrl.IP,
        Port: Number(multicastUrl.Port),
        Sid: Number(multicastUrl.Sid),
      };
    }
  });
  return !isEmptyObject(multicast) ? multicast : null;
}

export const parseChannel = (
  channel: IRawLiveChannel,
  groupType: string,
  urlFormatter: (url: string) => string,
): ILiveChannel | null => {
  // Skip the channels that don't have the mandatory fields.
  if (
    isNullOrUndefined(channel.Name) ||
    isNullOrUndefined(channel.EpgId) ||
    isDuplicateCanalChannelInUnauthorizedGroup(channel, groupType)
  ) {
    return null;
  }

  // It's ok for channel.WSXUrl to be undefined
  // however depending on the platform it might be an issue
  if (isNullOrUndefined(channel.WSXUrl)) {
    logger.warn(
      `OnePlayer > parseChannels > WSXUrl missing for channel: ${channel.Name}, epgId: ${channel.EpgId}`,
    );
  }

  let isChannelNbAnArray = false;
  let channelNumbering: number[][] = [[]];
  let number: number;
  if (Array.isArray(channel.Nb) && channel.Nb.length) {
    isChannelNbAnArray = true;
    channelNumbering = channel.Nb.map((channelNumbers) =>
      channelNumbers.map((channelNumber) => parseInt(channelNumber, 10)),
    );
    [[number]] = channelNumbering;
  } else {
    number = parseInt(channel.Nb.toString(), 10);
  }

  const streamUrls: IStreamUrls | null = channel.AssetUrls
    ? {
        ...(channel.AssetUrls.NoRight && {
          NoRight: urlFormatter(channel.AssetUrls.NoRight),
        }),
        ...(channel.AssetUrls.Dvb && {
          Dvb: urlFormatter(channel.AssetUrls.Dvb),
        }),
        ...(channel.AssetUrls.DvbUhd && {
          DvbUhd: urlFormatter(channel.AssetUrls.DvbUhd),
        }),
        ...(channel.AssetUrls.OttUrls && {
          Ott: {
            Default: urlFormatter(channel.AssetUrls.OttUrls.Default),
            ...(channel.AssetUrls.OttUrls.Sdr && {
              Sdr: urlFormatter(channel.AssetUrls.OttUrls.Sdr),
            }),
            ...(channel.AssetUrls.OttUrls.Hdr && {
              Hdr: urlFormatter(channel.AssetUrls.OttUrls.Hdr),
            }),
            ...(channel.AssetUrls.OttUrls.SdrLL && {
              SdrLL: urlFormatter(channel.AssetUrls.OttUrls.SdrLL),
            }),
            ...(channel.AssetUrls.OttUrls.HdrLL && {
              HdrLL: urlFormatter(channel.AssetUrls.OttUrls.HdrLL),
            }),
          },
        }),
        ...(channel.AssetUrls.IptvUrls && {
          Iptv: {
            Sd: urlFormatter(channel.AssetUrls.IptvUrls.Sd),
            Hd: urlFormatter(channel.AssetUrls.IptvUrls.Hd),
            Hdp: urlFormatter(channel.AssetUrls.IptvUrls.Hdp),
            Uhd: urlFormatter(channel.AssetUrls.IptvUrls.Uhd),
          },
        }),
        ...(channel.AssetUrls.MulticastUrls &&
          Array.isArray(channel.AssetUrls.MulticastUrls) && {
            Multicast: parseMulticastUrls(channel.AssetUrls.MulticastUrls),
          }),
        ...(channel.AssetUrls.Default && {
          Default: urlFormatter(channel.AssetUrls.Default),
        }),
        ...(channel.AssetUrls.Sdr && {
          Sdr: urlFormatter(channel.AssetUrls.Sdr),
        }),
        ...(channel.AssetUrls.Hdr && {
          Hdr: urlFormatter(channel.AssetUrls.Hdr),
        }),
        ...(channel.AssetUrls.SdrLL && {
          SdrLL: urlFormatter(channel.AssetUrls.SdrLL),
        }),
        ...(channel.AssetUrls.HdrLL && {
          HdrLL: urlFormatter(channel.AssetUrls.HdrLL),
        }),
      }
    : null;

  const WSXUrl = isNullOrUndefined(channel.WSXUrl)
    ? null
    : urlFormatter(channel.WSXUrl);

  return {
    name: channel.Name,
    normalizedName: removeDiacritics(channel.Name),
    groupType: parseInt(groupType, 10),
    lineUp: channel.LineUp,
    number,
    epgId: parseInt(channel.EpgId, 10),
    thematic: channel.Them,
    logoUrlRaw: channel.LogoUrl,
    logoUrl: template(channel.LogoUrl, { resolutionXY: '360x270' }),
    logoUrlLight: template(channel.LogoUrl.replace(/FB/g, 'FN'), {
      resolutionXY: '120x90',
    }),
    isChannelAuthorized:
      Boolean(channel.AccessRights.NoAccess === 'false') || false,
    isDVR: Boolean(channel.DVR === 'true') || false,
    isStartOverAuthorized:
      (Boolean(channel.DVR === 'true') && WSXUrl !== null) || false,
    isCat5Authorized:
      Boolean(channel.AccessRights.Cat5Access === 'true') || false,
    isFullCat5: Boolean(channel.FullCat5 === 'true') || false,
    isCastable: Boolean(channel.IsCastable === 'true') || false,
    isAdAntiSkip: Boolean(channel?.IsAdAntiSkip === 'true') || false,
    noSeekDurationForAdAtBeginning: channel?.NoSeekDurationForAdAtBeginning
      ? parseInt(channel.NoSeekDurationForAdAtBeginning, 10)
      : null,
    noSeekDurationForAdAfterBeginning:
      channel?.NoSeekDurationForAdAfterBeginning
        ? parseInt(channel.NoSeekDurationForAdAfterBeginning, 10)
        : null,
    hasCat5: Boolean(channel.HasCat5 === 'true') || false,
    hasEncryptedPrograms: Boolean(channel.Hybrid === 'true') || false,
    WSXUrl,
    WSXUrlLL: streamUrls?.SdrLL || null,
    ...(isChannelNbAnArray && { channelNumbering }),
    isAutostart: Boolean(channel.IsAutostart === 'true') || false,
    // only consider external services (not corner channel), to do so we should check
    // both properties ExtUrl and IsAutostart
    isExternalServiceChannel:
      (Boolean(channel.ExtUrl === 'true') &&
        Boolean(channel.IsAutostart === 'true')) ||
      false,
    ...(channel.ExtServiceUrl && { extServiceURL: channel.ExtServiceUrl }),
    ...(streamUrls && { streamUrls }),
  };
};

/**
 * Transform/Parse from a IRawLiveChannel interface to a ILiveChannel interface.
 * Make a data structure more usable inside the OnePlayer.
 * This concern data we receive from the InitLiveTV call.
 * We also make two distinct data structure between the channel that are authorized
 * to user to play and those that are not authorized.
 * For example, for a non authenticated user, we will receive all channels but most of them
 * will be non authorized since he is not a premium user.
 * @param channels list of channels retrieved with initlivetv call
 * @param groupType type of channels (authorized, non authorized, event)
 * @param RMUToken route me up token
 * @returns A sanitized/parsed LiveChannels
 */
const parseChannels = (
  channels: IRawLiveChannel[],
  groupType: string,
  RMUToken?: string,
): IParsedChannels => {
  const urlFormatter = (url: string): string =>
    RMUToken ? template(url, { RMUToken }) : url;

  return channels.reduce<IParsedChannels>(
    (acc, channel) => {
      const refinedChannelProps = parseChannel(
        channel,
        groupType,
        urlFormatter,
      );

      if (refinedChannelProps === null) {
        return acc;
      }

      if (refinedChannelProps.isChannelAuthorized) {
        acc.authorizedChannels.push(refinedChannelProps);
        acc.allChannels.push(refinedChannelProps);
        return acc;
      }
      acc.allChannels.push(refinedChannelProps);
      return acc;
    },
    { authorizedChannels: [], allChannels: [] },
  );
};

export default parseChannels;
