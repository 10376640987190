import { setCookie } from '@canalplus/mycanal-commons';
import { getDeviceIdCookieValue, type PassUserData } from '@canalplus/sdk-pass';
import Cookies from 'js-cookie';
import { CookieKey, DEVICE_ID_COOKIE_DURATION } from '../../constants/cookie';

export const setDeviceIdCookie = (passUserData: PassUserData): void => {
  const currentDeviceId = Cookies.get(CookieKey.DeviceId);

  if (currentDeviceId) {
    const [, currentIsAuthenticatedStatus] = currentDeviceId.split(':');
    const currentIsAuthenticatedStatusBool =
      currentIsAuthenticatedStatus === '1';
    const isAuthenticatedChanged =
      passUserData.isAuthenticated !== currentIsAuthenticatedStatusBool;

    if (!isAuthenticatedChanged) {
      return;
    }
  }

  setCookie(
    CookieKey.DeviceId,
    getDeviceIdCookieValue({
      previousDeviceId: currentDeviceId,
      isAuthenticated: passUserData.isAuthenticated,
    }),
    {
      expires: DEVICE_ID_COOKIE_DURATION,
    }
  );
};
