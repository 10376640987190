export class TimeoutError extends Error {
  timeout: number | undefined = 0;

  constructor(message: string, timeout?: number) {
    super(message);

    Object.setPrototypeOf(this, TimeoutError.prototype);

    this.timeout = timeout;

    this.name = 'TimeoutError';

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, TimeoutError);
    }
  }
}
