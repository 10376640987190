import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { ModalTypes } from '../../constants/modalTypes';

type ModalKey = {
  [key in ModalTypes]?: { isOpen: boolean };
};

export type ModalState = {
  modalType: ModalTypes;
  modalTypes: ModalTypes[];
  modalProps?: { [T: string]: any };
  clickedElement?: HTMLElement | null;
} & ModalKey;

export const initialState = {
  modalType: ModalTypes.GENERIC_MODAL,
  modalTypes: [],
} satisfies ModalState as ModalState;

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    /** Open modal */
    openModal(
      state,
      action: PayloadAction<
        Pick<ModalState, 'modalType' | 'modalProps' | 'clickedElement'>
      >
    ) {
      return {
        ...state,
        ...action.payload,
        clickedElement: action.payload.clickedElement && state.clickedElement,
        modalTypes: [...state.modalTypes, action.payload.modalType],
        [action.payload.modalType]: {
          isOpen: true,
        },
      };
    },

    /** Close modal */
    closeModal(state, action: PayloadAction<ModalTypes | undefined>) {
      return {
        ...state,
        ...(action.payload && { [action.payload]: undefined }),

        // modalType equals second to last item
        modalType:
          state.modalTypes[state.modalTypes.length - 2] ||
          ModalTypes.GENERIC_MODAL,
        modalTypes: state.modalTypes.filter((item) => item !== action.payload),
      };
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;

export const modalReducer = modalSlice.reducer;

export type ModalActions = ReturnType<
  (typeof modalSlice.actions)[keyof typeof modalSlice.actions]
>;
