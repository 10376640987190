import { isSomeEnum } from '@canalplus/mycanal-commons';

export enum SecondaryActionType {
  Download = 'download',
  Playlist = 'playlist',
  Opinion = 'opinion',
  Share = 'share',
  Recording = 'recording',
}

export const isSecondaryActionType = isSomeEnum(SecondaryActionType);
