export type LogData = {
  timestamp: number;
  level?: 'error' | 'warn' | 'info' | 'debug';
  tags?: string[];
  key: string;
  message: string;
};

export type LogListener = (data: LogData) => void;

const listeners: LogListener[] = [];

export function addLogListener(listener: LogListener): void {
  listeners.push(listener);
}

export function removeLogListener(listener: LogListener): void {
  const index = listeners.indexOf(listener);
  if (index > -1) {
    listeners.splice(index, 1);
  }
}

function identity(element?: HTMLElement):
  | undefined
  | {
      tag: string;
      id?: string;
      className?: string;
    } {
  if (!element) {
    return undefined;
  }

  return {
    tag: element.tagName.toLocaleLowerCase(),
    className: element.className,
    id: element.getAttribute('id') || undefined,
  };
}

if (typeof window !== 'undefined' && typeof window.R7 !== 'undefined') {
  const originalR7 = window.R7;

  // @ts-expect-error We can't patch R7 in a single line
  window.R7 = (...args: Parameters<typeof window.R7>): void => {
    originalR7(...args);
    log({
      level: 'info',
      tags: [`R7#${args[0]}`],
      message: JSON.stringify(args[1] || {}, null, 2),
    });
  };

  window.R7.grabKey = originalR7.grabKey;
  window.R7.releaseKey = originalR7.releaseKey;
}

export function log(data: {
  level?: 'error' | 'warn' | 'info' | 'debug';
  tags?: string[];
  message: string;
  element?: HTMLElement;
}): void {
  const logEvent = {
    timestamp: Date.now(),
    key: Math.random().toString().split('.')[1],
    html: identity(data.element),
    ...data,
  };
  for (let i = 0; i < listeners.length; i += 1) {
    listeners[i](logEvent);
  }
}
