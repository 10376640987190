import { isClientSide } from '@canalplus/mycanal-commons';

/**
 * Whether the performance API is enabled or not.
 *
 * Depending on:
 * - the environment (client or server side)
 * - the browser support
 * - the feature toggle value
 *
 * @param featClientPerfMeasures feature toggle value
 */
export const isPerformanceApiEnabled = (
  featClientPerfMeasures: boolean
): boolean => {
  if (!isClientSide()) {
    return false;
  }

  if (!window.performance) {
    return false;
  }

  return featClientPerfMeasures;
};
