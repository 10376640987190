import { isSomeEnum } from '@canalplus/mycanal-commons';

export enum Build {
  ES5 = 'es5',
  ES6 = 'es6',
}

export const isBuild = isSomeEnum(Build);

export const Es5BuildQuery = 'build=es5';
