import { ApiV2InitResponse } from '@dce-front/hodor-types';
import { sortProfiles } from '../../../../helpers/user/user-helper';
import {
  userListProfile,
  userListProfiles,
} from '../../../../store/slices/user';
import { profilesUpdateDateSelector } from '../../../../store/slices/user-selectors';
import {
  setCurrentProfile,
  setProfiles,
  setProfilesUpdateDate,
} from '../../../profiles/profileStorageService';
import { shouldStoreProfiles } from './shouldStoreProfiles';

type StoreProfilesParameters = {
  store: Redux.CustomStore;
  profiles: ApiV2InitResponse['userDetails']['profiles'];
};

export const storeProfiles = ({
  store,
  profiles,
}: StoreProfilesParameters): void => {
  const state = store.getState();

  if (profiles && shouldStoreProfiles(state)) {
    setProfiles(profiles);
    store.dispatch(userListProfiles(profiles));

    const sortedProfiles = sortProfiles(profiles);
    const currentProfile = sortedProfiles[0];

    if (currentProfile) {
      setCurrentProfile(currentProfile);
      store.dispatch(userListProfile(currentProfile));
    }

    const profilesUpdateDate = profilesUpdateDateSelector(state);
    setProfilesUpdateDate(profilesUpdateDate);
  }
};
