/*
 * Limits for listing, periods, etc.
 */

export const AVAILABILITY_EXPIRATION_DAYS = 305;

/** 14400000 = 4h */
export const PASS_TOKEN_VALIDITY = 14_400_000;

export const PROGRESS_BAR_COMPLETION_THRESHOLD = 90;
/** `60_000 ms = 1 minute` */
export const REFRESH_PROGRESS_BAR_MS = 60_000;
export const REFRESH_LIVE_ROW = 300_000;
