/**
 * Helper to determine current application run context
 *
 * This helper should be prefered to usage of IS_SERVER global in application code
 */

/**
 * Check if we are in server context
 *
 * @returns {bool}
 */
export const isServer = (): boolean => IS_SERVER;

/**
 * Check if we are in client/spa context
 *
 * @returns {bool}
 */
export const isClient = (): boolean => !IS_SERVER;
