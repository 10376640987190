import { PassPlatform } from '@canalplus/sdk-core';
import type { PassSdkConfig } from '@canalplus/sdk-pass';
import type { Request } from 'express';
import type { HttpsProxyAgent } from 'https-proxy-agent';
import type { XOR } from 'ts-essentials';
import {
  offerLocationSelector,
  offerZoneSelector,
  platformSelector,
  portailIdSelector,
} from '../../store/slices/application-selectors';
import { displayTVModeSelector } from '../../store/slices/displayMode-selectors';
import type { IState } from '../../store/types/State-type';
import { getClientId } from '../clientId/getClientId';
import { getPublicConfig, getServerConfig } from '../config/config-helper';
import Logger from '../logger/logger-helper';

export const getUserIp = ({ headers, connection }: Request): string =>
  (typeof headers?.['true-client-ip'] === 'string' &&
    headers['true-client-ip']) ||
  (typeof headers?.['x-forwarded-for'] === 'string' &&
    headers['x-forwarded-for']) ||
  connection.remoteAddress ||
  '';

type GetPassSdkConfigParameters = XOR<
  {
    state: IState;
    request?: never;
  },
  {
    request: Request;
    proxyAgent?: HttpsProxyAgent<string>;
    state?: never;
  }
>;

export const getPassSdkConfig = ({
  proxyAgent,
  request,
  state: argState,
}: GetPassSdkConfigParameters): PassSdkConfig => {
  const { baseHost, requestId } = request || {};
  const { baseUrl, clientIds, fetchOptions } = getPublicConfig().api.sdkPass;

  const state = request ? request.reduxStore.store.getState() : argState;
  const userIp = request ? getUserIp(request) : undefined;

  const portailId = portailIdSelector(state);
  const isTvDevice = displayTVModeSelector(state);

  return {
    baseUrl,
    clientId: getClientId(clientIds, baseHost || window.location.href),
    ...(request && {
      clientSecret: getClientId(
        getServerConfig().server.api.pass.clientSecrets,
        baseHost
      ),
    }),
    fetchOptions: {
      ...(request && {
        headers: {
          'user-agent': 'myCANAL/SSR',
          ...(typeof userIp === 'string' && {
            'true-client-ip': userIp,
            'x-forwarded-for': userIp,
          }),
        },
      }),
      ...(isTvDevice && {
        headers: {
          'user-agent': 'MyCANAL_TV',
        },
      }),
      ...fetchOptions,
      ...(proxyAgent && { agent: proxyAgent }),
    },
    logger: Logger,
    media: PassPlatform[platformSelector(state)],
    offerLocation: offerLocationSelector(state),
    offerZone: offerZoneSelector(state),
    portailId,
    vect: 'INTERNET',
    ...(requestId && {
      logMetadata: {
        requestId,
      },
    }),
  };
};
