import { createSelector } from '@reduxjs/toolkit';

export const downloadToGoSelector = (state) => state?.downloadToGo;

export const showDownloadToGoButtonSelector = createSelector(
  downloadToGoSelector,
  (downloadToGo) => downloadToGo?.showDownloadToGoButton
);

export const d2gConsumptionPlatformSelector = createSelector(
  downloadToGoSelector,
  (downloadToGo) => downloadToGo?.consumptionPlatform
);

export const isStreamAvailableSelector = createSelector(
  downloadToGoSelector,
  (downloadToGo) => downloadToGo?.isStreamAvailable
);

export const d2gContentIDSelector = createSelector(
  downloadToGoSelector,
  (downloadToGo) => downloadToGo?.contentID
);

export const downloadToGoAppUrlSelector = createSelector(
  downloadToGoSelector,
  (downloadToGo) => downloadToGo?.downloadToGoAppUrl
);

export const downloadContentUrlSelector = createSelector(
  downloadToGoSelector,
  (downloadToGo) => downloadToGo?.downloadContentUrl
);

export const downloadContentTrackingSelector = createSelector(
  downloadToGoSelector,
  (downloadToGo) => downloadToGo?.tracking
);

export const deletingContentSelector = createSelector(
  downloadToGoSelector,
  (downloadToGo) => downloadToGo?.deletingContent
);

export const downloadProgressionSelector = createSelector(
  downloadToGoSelector,
  (downloadToGo) => downloadToGo?.downloadProgression
);

export const downloadingContentSelector = createSelector(
  downloadToGoSelector,
  (downloadToGo) => downloadToGo?.downloadingContent || false
);
