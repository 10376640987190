import { createSelector } from '@reduxjs/toolkit';
import { ModalState } from '../../store/slices/modal';
import { IState } from '../../store/types/State-type';

const modalSelector = (state: IState): ModalState => state?.modal;

export const modalTypeSelector = createSelector(
  modalSelector,
  (modal) => modal?.modalType
);

export const isModalOpenSelector = createSelector(
  [modalSelector, modalTypeSelector],
  (modal, modalType) => modal?.[modalType]?.isOpen || false
);

export const modalPropsSelector = createSelector(
  modalSelector,
  (modal) => modal?.modalProps || {}
);

export const modalClickedElementSelector = createSelector(
  [modalSelector],
  (modal) => modal?.clickedElement
);
