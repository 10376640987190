import { closeImmersive } from '../../store/slices/immersive';
import { requestTemplate } from '../../store/slices/page';
import { DownloadToGoTypeKeys } from './actions/DownloadToGo-actions';
import initialState from './initialStates/DownloadToGo-initialState';

export const downloadToGoReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case DownloadToGoTypeKeys.UPDATE_DOWNLOAD_CONTENT_URL:
    case DownloadToGoTypeKeys.UPDATE_DOWNLOAD_TO_GO: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case closeImmersive.type:
    case requestTemplate.type: {
      return {};
    }
    case DownloadToGoTypeKeys.OPEN_DOWNLOAD_TO_GO:
    default:
      return state;
  }
};
