import { isClientSide } from '@canalplus/mycanal-commons';

/**
 * check whether R7extlib is available or not
 */
export const isR7Available = (): boolean => isClientSide() && !!window.R7;

/**
 * Based on isClientSide, check if the client has connection
 * true if client has a connection
 */
export const isConnectionActive = (): boolean =>
  !isClientSide() ? true : window.navigator.onLine;

/**
 * check if DOM and newrelic are available
 * true if DOM and newrelic are available
 */
export const canUseNewrelic = (): boolean =>
  isClientSide() && !!(window as any).newrelic;

/**
 * Returns visibilityChange depending on browser type
 */
export const getVisibilityChange = (): string | undefined => {
  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    return 'visibilitychange';
  }
  if (typeof (document as any).mozHidden !== 'undefined') {
    return 'mozvisibilitychange';
  }
  if (typeof (document as any).msHidden !== 'undefined') {
    return 'msvisibilitychange';
  }
  if (typeof (document as any).webkitHidden !== 'undefined') {
    return 'webkitvisibilitychange';
  }
  return undefined;
};
